import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import userRedurcer from "./accountSlice";
import tokenReducer from "./tokenSlice";
import transactionReducer from "./transactionsSlice";
import userSliceGraphReducer from "./userSlice";
import exchangeReducer from "./exchangeSlice";
import maeiSliceReducer from "./maeiSlice";
import graphSliceReducer from "./graphSlice";
import navbarReduecer from "./navbarSlice";
import offeringReducer from "./tokenOfferinSlice";
import investStoSlice from "./investStoSlice";
import stoInvestmentSlice from "./stoInvestmentSlice";
import appSettingsSlice from "./appSettingsSlice";
import tokensMetadataSlice from "./tokensMetadataSlice";
import activeStoSlice from "./activeStosSliece";
import transactionPageSlice from "./transactionPageSlice";

// We will use Mantine Notifications ... for now
// import { reducer as notificationsReducer } from "reapop";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userRedurcer,
    tokens: tokenReducer,
    transactions: transactionReducer,
    usergraph: userSliceGraphReducer,
    exchange: exchangeReducer,
    maei: maeiSliceReducer,
    graph: graphSliceReducer,
    navbar: navbarReduecer,
    tokenOfferings: offeringReducer,
    investsto: investStoSlice,
    stoinvestments: stoInvestmentSlice,
    appsettings: appSettingsSlice,
    tokensmetadata: tokensMetadataSlice,
    activeStos: activeStoSlice,
    transactionPage: transactionPageSlice,
    // notifications: notificationsReducer(),
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
