import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphClient";
import { gql } from "@apollo/client";
import { Asset, Data } from "./types/assets";

// Initial State
interface IGraphState {
  response?: Data;
  selectedTiker?: string;
  activeStos?: Asset[];
  loading: boolean;
  error: any;
}

const initialState: IGraphState = {
  loading: true,
  error: {},
};

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

// TODO: I dont like Admin DID being hardcoded here but I dont know how to get it from the backend
// Chache ...
export const fetchSubgraphData = createAsyncThunk(
  "graph/data",
  async ({
    userDid,
    adminDid = TOKEN_ISSUER_DID,
  }: {
    userDid: string;
    adminDid?: string;
  }) => {
    const query_odl = gql`
       { assetHolders(
                filter: {
                    identityId: {
                        equalTo: "${userDid}"
                    }
                }
            ) {
                nodes {
                    ticker: assetId
                    amount
                    createdAt
                    createdBlockId
            }
        }
            investments(
                filter: {	
                    investorId: {
                        equalTo: "${userDid}"
                    }
                }
                orderBy: DATETIME_DESC
            ) {
                totalCount
                nodes {
                    createdBlockId
                    investor: investorId
                    stoId
                    offeringToken
                    raiseToken
                    offeringTokenAmount
                    raiseTokenAmount
                    datetime
                }
            }
            assets(
                filter: {
                    ownerId: {
                        equalTo: "${adminDid}"
                    }
                }
                orderBy: CREATED_BLOCK_ID_DESC
            ) {
                nodes {
                    nodeId
                    createdBlockId
                    id
                    ticker
                    name
                    type                    
                    stosByOfferingAssetId {
                    nodes {
                        offeringAsset {
                          ticker
                        }
                        venueId
                        offeringPortfolioId
                        raisingAssetId
                        creatorId
                        start
                        end
                        tiers
                        status
                        minimumInvestment
                        createdAt
                        updatedAt
                      }
                    }
                    distributions { nodes {  id , identityId, currency, amount}}
                    fundings {
                        nodes {
                            nodeId
                            amount
                            totalFundingAmount
                            asset {
                                ticker
                                isFrozen
                            }
                            fundingRound
                            createdAt
                        }
                    }
                    totalSupply
                }
            }
        }
    `;
    const q1 = { query: query_odl };

    try {
      // await apolloClient.clearStore(); // Clear the cache
      // await apolloClient.cache.reset(); // Clear the cache
      const { data } = await apolloClient.query(q1);
      return data;
    } catch (error) {
      console.log("Graph Error  => ");
      console.log("================================");
      console.log(error);
      console.log("================================");
      return {};
    }
  }
);

export const subGraphSlice = createSlice({
  name: "graphdata",
  initialState,
  reducers: {
    selecAssetsWithActiveSto: (state) => {
      console.log("Current state:", JSON.parse(JSON.stringify(state.response)));

      let jsonData = JSON.parse(JSON.stringify(state.response)) as Data;

      // Function to check if a date is after thecurrent date
      const isDateAfterNow = (dateStr: string) =>
        new Date(dateStr) > new Date();

      // Filter the assets based on the criteria using Lodash
      const filteredAssets = _.filter(jsonData.assets.nodes, (asset: Asset) => {
        const stoNodes = asset.stosByOfferingAssetId.nodes;

        // Check if any of the nodes within stosNodes have nodes > 0
        const hasNodesGreaterThanZero = _.some(
          stoNodes,
          (node) => node.tiers.length > 0
        );

        // Check if the status is "Live" and the end date is after the current date
        const isLiveAndEndDateAfterNow = _.some(
          stoNodes,
          (node) => node.status === "Live" && isDateAfterNow(node.end)
        );

        // Return true if all criteria are met
        return hasNodesGreaterThanZero && isLiveAndEndDateAfterNow;
      });

      // console.log("Filtered Assets :::::  ====>>> ", filteredAssets);
      state.activeStos = filteredAssets;
    },
    selectAsset: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubgraphData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubgraphData.fulfilled, (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(fetchSubgraphData.rejected, (state, action) => {
      console.log("fetchSubgraphData.rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const selectAssetHolders = (state: any) =>
  state.response?.assetHolders.nodes;

export const selecAssetsWithActiveSto_func = (state: any): Asset[] => {
  let jsonData = JSON.parse(JSON.stringify(state.response)) as Data;

  // Function to check if a date is after thecurrent date
  const isDateAfterNow = (dateStr: string) => new Date(dateStr) > new Date();

  // Filter the assets based on the criteria using Lodash
  const filteredAssets = _.filter(jsonData.assets.nodes, (asset: Asset) => {
    const stoNodes = asset.stosByOfferingAssetId.nodes;

    // Check if any of the nodes within stosNodes have nodes > 0
    const hasNodesGreaterThanZero = _.some(
      stoNodes,
      (node) => node.tiers.length > 0
    );

    // Check if the status is "Live" and the end date is after the current date
    const isLiveAndEndDateAfterNow = _.some(
      stoNodes,
      (node) => node.status === "Live" && isDateAfterNow(node.end)
    );

    // Return true if all criteria are met
    return hasNodesGreaterThanZero && isLiveAndEndDateAfterNow;
  });

  // console.log("Filtered Assets :::::  ====>>> ", filteredAssets);
  return filteredAssets;
};

export const graphSliceSelector = (state: any) => state.graph;
export const { selectAsset, selecAssetsWithActiveSto } = subGraphSlice.actions;
export default subGraphSlice.reducer;
