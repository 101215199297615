import {
  Button,
  Drawer,
  Group,
  LoadingOverlay,
  NativeSelect,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useState, useContext } from "react";
import { showNotification, updateNotification } from "@mantine/notifications";

import {
  useAppSelector,
  useAppDispatch,
  closeDepositWindow,
} from "../../app/index";
import Context from "../../context/Context";
import DepositContent from "./DepositContent";

const API_URL = process.env.REACT_APP_API_URL;

const data = [
  { value: "AUD", label: "🇦🇺 AUD" },
  { value: "USD", label: "🇺🇸 USD" },
];

// function Title() {
//   return <h2>Deposit</h2>;
// }

export default function Deposit() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  const { depositWindowOpened } = useAppSelector((state) => state.maei);
  const { service } = context.globalstate;

  const form = useForm({
    initialValues: {
      currency: "AUD",
      amount: "",
      transactionType: 1,
    },
  });

  // Get inferred form values type, will be `{ name: string; age: number }`
  type FormValues = typeof form.values;

  // TODO: Move to REDUX
  const handleSubmit = async (values: FormValues) => {
    setProcessing(true);

    showNotification({
      color: "teal",
      id: "load-data",
      loading: true,
      title: "Processing Transaction",
      message: "Please wait while we are processing your transaction",
      autoClose: false,
      // disallowClose: true,
    });

    const response = await service.request.post(
      `${API_URL}/user/funds`,
      values
    );

    setTimeout(() => {
      setProcessing(false);
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Transaction Completed",
        message:
          "Please check your transaction status in your transaction history page",
        icon: <IconCheck size={16} />,
        autoClose: 3000,
        onClose: () => {
          dispatch(closeDepositWindow(1));
        },
      });
    }, 3000);
  };

  function handleClose() {
    dispatch(closeDepositWindow(1)); // TODO: Remove paseed argument or Optimize it via Hooks
  }

  const select = (
    <NativeSelect
      data={data}
      size="md"
      {...form.getInputProps("currency")}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }}
    />
  );

  // useEffect(() => {}, []);

  return (
    <Drawer
      opened={depositWindowOpened}
      onClose={() => handleClose()}
      title={"Deposit"}
      padding="lg"
      size="md"
      position="right"
      withOverlay={true}
      classNames={{ title: "drawer-title" }}
    >
      <LoadingOverlay visible={processing} />

      <SimpleGrid cols={1} spacing="xl" verticalSpacing="xl">
        <div>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              type="number"
              placeholder="1000"
              label="Deposit amount"
              rightSection={select}
              rightSectionWidth={113}
              size="md"
              {...form.getInputProps("amount")}
            />

            <Group position="right" spacing="xl" mt={9}>
              <Button
                type="submit"
                color="dark"
                size="md"
                classNames={{ root: "bg-brand-main" }}
              >
                Send Request
              </Button>
            </Group>
          </form>
        </div>
      </SimpleGrid>

      <br />

      <DepositContent />
    </Drawer>
  );
}
