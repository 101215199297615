import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphClient";
import { gql } from "@apollo/client";

export interface IStoInvestments {
  investments: {
    nodes: Node[];
  };
}

export interface Node {
  createdBlockId: string;
  datetime: Date;
  id: string;
  offeringToken: string;
  offeringTokenAmount: number;
  raiseTokenAmount: number;
  raiseToken: string;
  stoId: number;
  investorId: string;
  createdAt: Date;
  updatedAt: Date;
}

// Initial State
interface IGraphStoInvestmentState {
  response: IStoInvestments;
  loading: boolean;
  error: any;
}

const initialState: IGraphStoInvestmentState = {
  response: { investments: { nodes: [] } },
  loading: true,
  error: {},
};

// TODO: I dont like Admin DID being hardcoded here but I dont know how to get it from the backend
export const fetchInvestment = createAsyncThunk(
  "graph/investments",
  async ({ userDid }: { userDid: string }) => {
    const g_query = gql`
      query Investments {
        investments(
          filter: {
            investorId: {
              equalTo: "${userDid}"
            }
          }
          orderBy: DATETIME_DESC
        ) {
          nodes {
            createdBlockId
            datetime
            id
            offeringToken
            offeringTokenAmount
            raiseTokenAmount
            raiseToken
            stoId
            investorId
            createdAt
            updatedAt
          }
        }
      }
    `;
    const q = { query: g_query };

    try {
      const { data } = await apolloClient.query(q);
      console.log("Graph Response  => ", data);
      return data;
    } catch (error) {
      console.log("Graph Error  => ");
      console.log("================================");
      console.log(error);
      console.log("================================");
      return {};
    }
  }
);

export const stoInvestmentSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    selectAsset: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvestment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInvestment.fulfilled, (state, { payload }) => {
      console.log("fetchInvestment.fulfilled => ", payload);
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(fetchInvestment.rejected, (state, action) => {
      console.log("fetchSubgraphData.rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const stoInvestmentSliceSelector = (state: any) => state.investments;
export default stoInvestmentSlice.reducer;
