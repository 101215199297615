import {
  AppShell,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Modal,
  PasswordInput,
  SimpleGrid,
  TextInput,
  Text,
  Divider,
  Grid,
  Container,
} from "@mantine/core";
import { AppHeader, AppNavbar } from "../Components/Index";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery, useViewportSize } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { showNotification, updateNotification } from "@mantine/notifications";
import Context from "../../context/Context";
import {
  useAppDispatch,
  useAppSelector,
  fetchExchangeOffers,
} from "../../app/index";
import { IconCheck, IconError404 } from "@tabler/icons-react";
import AccountInfoCard from "./AccountAddressInfoCard";
import { useLoggedUser } from "../../helper";
import { divide } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

interface IchangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function Settings() {
  const [opened, { open, close }] = useDisclosure(false);
  const [processing, setProcessing] = useState(false);
  const [passwordIconVisible, setPasswordIconVisible] = useState(false);
  const [error, setError] = useState(false);
  const [errortext, setErrorText] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const matches = useMediaQuery("(min-width: 56.25em)");
  const { height, width } = useViewportSize();

  const loggedUser = useLoggedUser();
  const context = useContext(Context);
  const { service } = context.globalstate;

  useEffect(() => {
    console.log("MediaQuery :::: ", matches);
  }, [value]);

  const form = useForm({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  type FormValues = typeof form.values;

  const openChangePasswordModal = () => {
    console.log("openChangePasswordModal");
    form.reset();
    setProcessing(false);
    setError(false);
    setErrorText("");
    open();
  };

  const handleSubmit = async (values: FormValues) => {
    console.log("handle Submit !!! ", values);
    setError(false);
    setErrorText("");

    // Check if the new password and the confirm password are the same
    if (values.newPassword !== values.confirmNewPassword) {
      console.log("Passwords are not the same !!!");
      setError(true);
      setErrorText("The passwords you entered do not match. Please try again");
      return;
    }

    // Check if the new password is the same as the old password
    if (values.newPassword === values.currentPassword) {
      console.log("The new password is the same as the old password");
      setError(true);
      setErrorText(
        "Your new password cannot be the same as the current one. Please choose a different password to ensure your account's security."
      );
      return;
    }

    // Change setProccessing statius after local check is passwed
    setProcessing(true);

    showNotification({
      color: "teal",
      id: "load-data",
      loading: true,
      title: "Processing Transaction",
      message: "Please wait while we are processing your transaction",
      autoClose: false,
    });

    // All cheks are passed now submit the form data

    const changeRequest: IchangePasswordRequest = {
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmNewPassword,
    };

    console.log("Request Data: ", changeRequest);

    const { data: response, status: httpStatusCode } =
      await service.request.post(
        `${API_URL}/user/account/passowrd`,
        changeRequest
      );

    console.log(
      "Change Password Response ==> ",
      response,
      "Status Code: ",
      httpStatusCode
    );

    const notificationStatuseColor = httpStatusCode === 200 ? "teal" : "red";
    const notificationTitle = httpStatusCode === 200 ? "Success" : "Error";

    const notficationIcon =
      httpStatusCode === 200 ? (
        <IconCheck size={16} />
      ) : (
        <IconError404 size={16} />
      );

    console.log("RESPONSE: ", response);
    const { message } = response;

    setProcessing(false);

    updateNotification({
      id: "load-data",
      color: notificationStatuseColor,
      title: notificationTitle,
      message: message,
      icon: notficationIcon,
      onClose: () => {
        console.log("Close Modal Notification");
        form.reset();
      },
    });

    // close modal if the success
    if (httpStatusCode === 200) {
      close(); //close modal
    }
  };

  const formValuesChanged = async (values: FormValues) => {
    console.log("formValuesChanged");
  };

  // return (
  //   <>
  //     <div className="w-full h-full bg-[url('assets/Dashboard2Bg.png')] bg-no-repeat bg-cover bg-bottom md:px-10 px-4 py-1">
  //       <Grid>
  //         <Grid.Col span={width <= 600 ? 12 : 7}>Width: {width}</Grid.Col>
  //         <Grid.Col span={width <= 600 ? 12 : 5}>Width: {width}</Grid.Col>
  //       </Grid>
  //     </div>
  //   </>
  // );

  return (
    <div className="w-full h-full bg-[url('assets/Dashboard2Bg.png')] bg-no-repeat bg-cover bg-bottom md:px-10 px-4 py-1">
      <Grid grow gutter="xl">
        <Grid.Col span={width <= 700 ? 12 : 7}>
          <AccountInfoCard />
        </Grid.Col>
        <Grid.Col span={width <= 700 ? 12 : 5}>
          <Card shadow="sm" padding="xl" mt={20}>
            <Card.Section inheritPadding py="xs">
              <Group position="apart">
                <Text size={"xl"} weight={900}>
                  Sign-In
                </Text>
              </Group>
            </Card.Section>

            <Card.Section withBorder inheritPadding py="xs">
              <TextInput
                disabled={true}
                label="UserName"
                value={loggedUser?.username}
              ></TextInput>

              <br />
              <SimpleGrid>
                <div>
                  <PasswordInput
                    disabled={true}
                    placeholder="Password"
                    label="Password"
                    value={"***********************"}
                  />
                </div>
                <div>
                  <Group position="right" mt="sx">
                    <Button
                      color={"dark"}
                      className="w-full h-[54px] flex items-center justify-center text-base text-white font-semibold font-sans rounded-[9px] bg-gradient-to-b from-brand-main to-brand-secondary"
                      onClick={openChangePasswordModal}
                    >
                      Change Password ...
                    </Button>
                  </Group>
                </div>
              </SimpleGrid>
            </Card.Section>
          </Card>
        </Grid.Col>
        {/* <Grid.Col span={4}>---</Grid.Col> */}
      </Grid>

      {/* <!-- Change Password Modal --> */}
      <>
        <Modal
          opened={opened}
          onClose={close}
          title="Change Password"
          size={"lg"}
        >
          <LoadingOverlay visible={processing} overlayBlur={0.5} />

          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <PasswordInput
              label="Current Password"
              withAsterisk
              mt={"md"}
              required
              visible={passwordIconVisible}
              onVisibilityChange={setPasswordIconVisible}
              {...form.getInputProps("currentPassword")}
            />

            <PasswordInput
              label="New Passwrd"
              withAsterisk
              mt={"md"}
              required
              visible={passwordIconVisible}
              onVisibilityChange={setPasswordIconVisible}
              {...form.getInputProps("newPassword")}
            />

            <PasswordInput
              label="Confirm New Password"
              withAsterisk
              mt={"md"}
              required
              visible={passwordIconVisible}
              onVisibilityChange={setPasswordIconVisible}
              {...form.getInputProps("confirmNewPassword")}
            />

            {error && (
              <Text fz="lg" c={"red"} fw={500} mt={10} color="{red}">
                {errortext}
              </Text>
            )}

            <Group position="right" mt="sx">
              <Button
                disabled={processing}
                color={"dark"}
                type="submit"
                mt="xl"
                // className="w-full h-[54px] flex items-center justify-center text-base text-white font-semibold font-sans rounded-[9px] bg-gradient-to-b from-brand-main to-brand-secondary"
              >
                Change Password
              </Button>
            </Group>
          </form>
        </Modal>
      </>
    </div>
  );
}
