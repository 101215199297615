import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconError404 } from "@tabler/icons-react";
import {
  useAppDispatch,
  useAppSelector,
  fetchExchangeOffers,
} from "../../app/index";
import Context from "../../context/Context";
import { AssetHolder } from "../../app/types/assets";
const API_URL = process.env.REACT_APP_API_URL;

export default function SellTokenModal(asset: AssetHolder) {
  const [opened, { open, close }] = useDisclosure(false);
  const [processing, setProcessing] = useState(false);

  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const exchangeData = useAppSelector((state) => state.exchange);
  const { service } = context.globalstate;

  const form = useForm({
    initialValues: {
      ticker: asset.ticker,
      amount: "",
      price: "",
      type: "sell",
    },
  });

  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    console.log("handle Submit !!! ", values);

    setProcessing(true);

    showNotification({
      color: "teal",
      id: "load-data",
      loading: true,
      title: "Processing Transaction",
      message: "Please wait while we are processing your transaction",
      autoClose: false,
      // disallowClose: true,
    });

    close();

    console.log("Form Data : ", values);

    const { data: response, status: httpStatusCode } =
      await service.request.put(`${API_URL}/exchange/offers`, values);

    console.log(
      "Exchange Transaction Response ==> ",
      response,
      "Status Code: ",
      httpStatusCode
    );

    const notificationStatuseColor = httpStatusCode === 200 ? "teal" : "red";
    const notificationTitle = httpStatusCode === 200 ? "Success" : "Error";
    const notficationIcon =
      httpStatusCode === 200 ? (
        <IconCheck size={16} />
      ) : (
        <IconError404 size={16} />
      );

    const { msg } = response;

    updateNotification({
      id: "load-data",
      color: notificationStatuseColor,
      title: notificationTitle,
      message: msg,
      icon: notficationIcon,
      onClose: () => {
        console.log("Close Modal Notification");
        form.reset();
      },
    });

    dispatch(fetchExchangeOffers(service));
  };

  return (
    <>
      <Group position="center">
        <Button color="dark" size="xs" variant="outline" onClick={open}>
          Sell
        </Button>
      </Group>

      <Modal
        opened={opened}
        onClose={close}
        title="Sell Tokens"
        centered={true}
      >
        <LoadingOverlay visible={processing} overlayBlur={0.5} />

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            type="number"
            mt={"md"}
            withAsterisk
            label="Amount Tokens to Sell"
            placeholder="100"
            required
            {...form.getInputProps("amount")}
          />

          <TextInput
            mt={"md"}
            withAsterisk
            label="Price"
            required
            {...form.getInputProps("price")}
          />

          <Group position="right" mt="sx">
            <Button
              color="dark"
              type="submit"
              mt="xl"
              className="bg-black bg-gradient-to-b from-brand-main to-brand-secondary"
            >
              Place Order
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
}
