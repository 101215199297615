// THIS IS NOT USED ANYWHERE

import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const GRAPH_ENDPOINT = process.env.REACT_APP_GRAPH_ENDPOINT;
const MAEI_ADMIN_DID = process.env.REACT_APP_MAEI_ADMIN_DID;

interface AssetHolders {
  nodes: {
    ticker: string;
    amount: string;
  }[];
}

interface Investments {
  totalCount: number;
  nodes: {
    createdBlockId: string;
    investor: string;
    stoId: number;
    offeringToken: string;
    raiseToken: string;
    offeringTokenAmount: string;
    raiseTokenAmount: string;
    datetime: string;
  }[];
}

interface Assets {
  nodes: {
    nodeId: string;
    id: string;
    ticker: string;
    name: string;
    type: string;
  }[];
}

interface RootObject {
  assetHolders: AssetHolders;
  investments: Investments;
  assets: Assets;
}

interface IUserGraphState {
  response: RootObject | null;
  loading: boolean;
  error: any;
}

const initialState: IUserGraphState = {
  response: null,
  loading: false,
  error: null,
};

export const userSliceGraph = createSlice({
  name: "usergraph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      console.log("Graph User Pending");
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      console.log("Graph User => Fulfilled Payload => ", payload.data);
      state.response = payload.data;
      state.loading = false;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      console.log("Graph User => Rejected");
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const fetchUser = createAsyncThunk(
  "usergraph/data",
  async (did: string) => {
    console.log("Graph User => Fetching => did => ", did);
    let USER_ASSETS_QUERY = `
          query {
              assetHolders(
                filter: {
                  identityId: {
                    equalTo: "${did}"
                  }
                }
              ) {
                nodes {
                  ticker: assetId
                  amount
                }
              }

              investments(
                filter: {
                  investorId: {
                    equalTo: "${did}"
                  }
                }
              ) {
                totalCount
                nodes {
                  createdBlockId
                  investor: investorId
                  stoId
                  offeringToken
                  raiseToken
                  offeringTokenAmount
                  raiseTokenAmount
                  datetime
                }
              }

              assets(
                filter: {
                  ownerId: {
                    equalTo: "${MAEI_ADMIN_DID}"
                  }
                },  orderBy : CREATED_BLOCK_ID_DESC
              ) {
                nodes {
                  nodeId
                  id
                  ticker
                  name
                  type
                }
              }
        }`;

    console.log(
      "Graph User => Fetching => USER_ASSETS_QUERY => ",
      USER_ASSETS_QUERY
    );
    try {
      const { data: response } = await axios({
        url: GRAPH_ENDPOINT,
        method: "post",
        data: { query: USER_ASSETS_QUERY },
      });

      return response;
    } catch (error) {
      console.log("Graph User => Fetching => Error => ", error);
      return error;
    }
  }
);

// TODO: Rename userSlice to userSliceGraph
export const userSliceGraphStateSelector = (state: any) => state.usergraph;
export default userSliceGraph.reducer;
