import {
  Avatar,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  useAppSelector,
  useAppDispatch,
  fetchTokenOfferings,
  IOfferingDetails,
} from "../../app/index";
import Context from "../../context/Context";
import StoCard from "../Sto/StoCard";
import _ from "lodash";
import { extractLetters } from "../../helper";

export default function TokenDetailsPageNew() {
  const [ticker, setTicker] = useState<string>("");
  const params = useParams();
  const context = useContext(Context);
  const dispatch = useAppDispatch();

  // Token Offerings loading from REDUX
  const tokenOfferings = useAppSelector((state) => state.tokenOfferings);

  // Tokens Metadata
  const tokensMetadata = useAppSelector((state) => state.tokensmetadata);
  const tokenMetaData = tokensMetadata.assets[ticker];

  // Order by ID
  const stos = _.orderBy(tokenOfferings.response, ["id"], ["desc"]);
  const { service } = context.globalstate;

  useEffect(() => {
    // TODO: remove this ezotheric part and make code clean !!!
    setTicker(params.ticker || "");
    let _ticker = params.ticker || "";
    dispatch(fetchTokenOfferings({ ticker: _ticker, service: service }));
  }, []);

  const offeringList = stos.map(
    (offering: IOfferingDetails, key: number) => (
      console.log("offering", offering),
      (
        <Grid.Col span={6}>
          <StoCard test="Test" {...offering} />
        </Grid.Col>
      )
    )
  );

  return (
    <div className="w-full h-full bg-[url('assets/Dashboard2Bg.png')] bg-no-repeat bg-cover bg-bottom md:px-10 px-4 py-1">
      <Grid grow gutter="xl">
        <Grid.Col span={12}>
          <Group>
            {!tokenMetaData?.logo && (
              <Avatar radius="xl" size="lg">
                {extractLetters(ticker)}
              </Avatar>
            )}

            {tokenMetaData?.logo && (
              <Avatar radius="xl" size="lg" src={tokenMetaData.logo}></Avatar>
            )}

            <Title order={3} size="h1">
              {ticker}
            </Title>
          </Group>
        </Grid.Col>
      </Grid>

      <Grid grow gutter="xl">
        <Grid.Col span={12}>
          <Text size="xl" fw={500}>
            {" "}
            {tokenMetaData?.desc}{" "}
          </Text>
          {/* <Text size="lg"> Terms month: {tokenMetaData?.term_month} </Text> */}
        </Grid.Col>
      </Grid>

      <Divider
        mt={15}
        size="xs"
        label="Security Token Offerings"
        labelPosition="center"
      />

      <Grid grow gutter="xl">
        {offeringList}
      </Grid>
    </div>
  );
}
