import React, { useContext, useEffect } from "react";
import { Avatar, Menu } from "@mantine/core";
import { BsChevronDown } from "react-icons/bs";
import { PageObject, pagesMetadata } from "../app/storage/pagesMetaData";
import { Link, useLocation } from "react-router-dom";
import Context from "../context/Context";
import DashboardSideBar from "./SideBar";

import {
  IconLogout,
  IconMessageCircle,
  IconSettings,
} from "@tabler/icons-react";
import { extractLetters, useLoggedUser } from "src/helper";

interface Props {
  children?: React.ReactNode;
}
const Layout: React.FC<Props> = ({ children }: Props) => {
  const context = useContext(Context);
  const loggedUser = useLoggedUser();
  const [pageName, setPageName] = React.useState("");
  let location = useLocation();

  const showSideBar = true;

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const activePageData: PageObject | undefined = pagesMetadata.get(
      pathArray[1]
    );
    if (activePageData) {
      setPageName(activePageData.title);
    } else {
      setPageName("");
    }
  }, [location]);

  return (
    <React.Fragment>
      {!showSideBar && (
        <main className={`w-full min-h-screen`}>{children}</main>
      )}{" "}
      {showSideBar && (
        <div
          className={`w-full h-full md:h-screen md:grid md:grid-cols-[230px,calc(100%-230px)] flex flex-col gap-6 md:gap-0
        `}
        >
          <DashboardSideBar />
          <main className="w-full h-full flex flex-col items-center justify-start gap-4 md:h-screen overflow-y-auto bg-gray-100/30 font-manrope md:mt-0 mt-20">
            <div className="w-full flex sm:flex-row flex-col sm:gap-0 gap-2 sm:items-center items-start sm:justify-between justify-start md:px-10 px-4 py-4">
              <div className="flex flex-col items-start justify-start gap-2 sm:text-center">
                {/* <h2 className="text-lg page-title sm:page-title-1  md:text-2">{`Welcome back to ${pageName} `}</h2> */}
                <h2 className="text-lg page-title">{`Welcome back to ${pageName} `}</h2>
                <p className="text-sm font-normal text-gray-main">
                  Investor Dashboard
                </p>
              </div>
              <div className="hidden sm:flex items-center justify-start gap-3">
                <p className="text-base text-black font-medium"></p>

                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <div className="flex items-center justify-start gap-3">
                      <p className="text-base text-black font-medium">
                        {loggedUser?.username}
                      </p>
                      <Avatar
                        classNames={{
                          root: "bg-gray-950	cursor-pointer",
                          placeholder:
                            "text-white font-manrope font-medium bg-gray-950 border-none",
                        }}
                        radius="xl"
                      >
                        {extractLetters(loggedUser?.username)}
                      </Avatar>
                      <BsChevronDown className="text-[18px] cursor-pointer" />
                    </div>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item icon={<IconSettings size={14} />}>
                      <Link to={"/settings"}>Settings</Link>
                    </Menu.Item>
                    <Menu.Item
                      disabled={true}
                      icon={<IconMessageCircle size={14} />}
                    >
                      Notifications
                    </Menu.Item>

                    <Menu.Divider />
                    <Menu.Item
                      icon={<IconLogout size={14} />}
                      onClick={() => context.handlers.logout()}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
            {children}
          </main>
        </div>
      )}
    </React.Fragment>
  );
};

export default Layout;
