import {
  IconChartBar,
  IconHome2,
  IconListCheck,
  IconShield,
  IconWallet,
} from "@tabler/icons-react";
import { BsBarChart } from "react-icons/bs";
import { GoHome } from "react-icons/go";

export const linksData = [
  { link: "/", label: "Home", icon: IconHome2 },
  //   { link: "", label: "My Assets", icon: IconWallet },
  { link: "/tokens", label: "Security Tokens", icon: IconShield },
  // { link: "/sto", label: "STO", icon: IconChartDonut2 },
  { link: "/exchange", label: "Token Exchange", icon: IconChartBar },
  { link: "/transactions", label: "Transactions", icon: IconListCheck },
];
