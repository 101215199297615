import { AppShell, Divider, Grid } from "@mantine/core";
import { AppHeader, AppNavbar } from "../Components/Index";
import {
  useAppSelector,
  useAppDispatch,
  fetchTokenOfferings,
  IOfferingDetails,
} from "../../app/index";
import Context from "../../context/Context";
import { useContext, useEffect, useState } from "react";
import StoCard from "./StoCard";

export default function StoPage() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();

  const tokenOfferings = useAppSelector((state) => state.tokenOfferings);
  const { service } = context.globalstate;

  useEffect(() => {
    dispatch(fetchTokenOfferings({ ticker: "TICKER", service: service }));
  }, []);

  const offeringList = tokenOfferings.response.map(
    (offering: IOfferingDetails, key: any) => (
      <StoCard test="Test" {...offering} key={key} />
    )
  );

  return (
    <AppShell navbar={<AppNavbar />} header={<AppHeader />}>
      <Grid>
        <Grid.Col span={7}>
          <h1>Sto Page .... </h1>
          <Divider />
          <br />
          {offeringList}
        </Grid.Col>
        <Grid.Col span={5}></Grid.Col>
      </Grid>
    </AppShell>
  );
}
