import { Text, ScrollArea, createStyles, Alert, List } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useLoggedUser } from "src/helper";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },
}));

export default function DepositInstructions() {
  const user = useLoggedUser();
  const { classes } = useStyles();

  return (
    <ScrollArea style={{ height: 550 }}>
      <Text size="sm">
        In order to purchase security tokens, you need to credit your account
        with Maei.
      </Text>

      <Text size={"sm"} mt={"xs"}>
        MAEI is a digital currency, always valued at 1:1 with the Australian
        Dollar (AUD), providing a safeand stable means for investors to
        participate in STOs.
      </Text>

      <Text size={"sm"} mt={"sm"}>
        By sending the request, you will be transferring the nominated amount
        from your bank account to the Marketlend Trust held by our independent
        trustee, the Bank of New York Mellon.
      </Text>

      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Bank Information"
        radius="xs"
        color="gray"
        mt={"sm"}
      >
        <List>
          <List.Item>
            Account Name: <strong>Marketlend Trust Bank</strong>
          </List.Item>
          <List.Item>
            Commonwealth BankBSB: <strong>062 000</strong>{" "}
          </List.Item>
          <List.Item>
            Account No: <strong>15994294</strong>{" "}
          </List.Item>
          <List.Item>
            Reference: <strong>{`Username: ${user.username}`}</strong>{" "}
          </List.Item>
        </List>
      </Alert>

      <Text size="sm" color="black" mt="md" mb="lg" weight={700}>
        FOR TRANSFERS OUTSIDE AUSTRALIA (the following additional information):
      </Text>

      <Alert
        icon={<IconAlertCircle size={16} />}
        title="Bank Information (international transfers)"
        radius="xs"
        color="gray"
      >
        <List>
          <List.Item>
            Account Name: <strong>Marketlend TrustBank</strong>
          </List.Item>
          <List.Item>
            Commonwealth Bank BSB: <strong>062 000</strong>{" "}
          </List.Item>
          <List.Item>
            Account No: <strong>15994294</strong>{" "}
          </List.Item>
          <List.Item>
            Reference: <strong>{"Your username"}</strong>{" "}
          </List.Item>
        </List>
      </Alert>

      <br />
      <br />
      <br />
    </ScrollArea>
  );
}
