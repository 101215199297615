import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IOfferingDetails } from "./types/index";
import { showNotification, updateNotification } from "@mantine/notifications";

import { IconCheck, IconX } from "@tabler/icons-react";

interface InvestWindowState {
  investWindowOpened: boolean;
  selectedSto?: IOfferingDetails | null;
  response: any;
  loading: boolean;
  error: any;
}

// TODO: Not finished ....
export interface IInvestSTORequest {
  purchaseAmount: number;
  stoid: number;
  ticker: string;
}

interface IInvestSTOResponse {
  success: boolean;
  message?: string;
  tx?: string | null;
}

const initialState: InvestWindowState = {
  investWindowOpened: false,
  selectedSto: null,
  response: {},
  loading: false,
  error: {},
};

const API_URL = process.env.REACT_APP_API_URL;

const mockresponseOK = {
  success: true,
  message: "The transaction has been successfully completed.",
  data: {
    status: true,
    tx: "0x15796edc51aa8228d98b7697ce7b13a13a2b52bea1a4ff1b84892f03ab418a65",
    msg: "The transaction has been successfully completed.",
  },
};

const mockresponseError = {
  success: false,
  message: "Unspecified Error ... ",
  data: {
    status: false,
    tx: "0x...",
    msg: "Unspecified Error ... ",
  },
};

export const sendInvestSTOTransaction = createAsyncThunk(
  "investSTO/sendTransaction",
  async ({ data, service }: { data: IInvestSTORequest; service: any }) => {
    try {
      console.log("==> Invest STO Request AsyncThunk => ", data);

      showNotification({
        color: "teal",
        id: "load-data",
        loading: true,
        title: "Processing Transaction",
        message: "Please wait while we are processing your transaction",
        autoClose: false,
        withCloseButton: false,
      });

      const { data: response } = await service.request.post(
        `${API_URL}/user/sto/buy`,
        data
      );

      // console.log("==> Invest STO Response AsyncThunk => ", response);
      return response;

      // return mockresponseError;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }
);

function _updateNotification(data: IInvestSTOResponse) {
  const { success, message, tx } = data;
  const notificationColor = success ? "teal" : "red";
  const title = success ? "Transaction Completed" : "Transaction Failed";

  updateNotification({
    id: "load-data",
    color: notificationColor,
    title: title,
    message: message,
    autoClose: false,
    // icon: <IconX />,
    // autoClose: 3000,
    onClose: () => {
      console.log("==> Notification Closed");
      changeInvestWindowState(null);
    },
  });
}

export const ivestStoSlice = createSlice({
  name: "invest/sto",
  initialState,
  reducers: {
    changeInvestWindowState: (state, data) => {
      state.selectedSto = data.payload;
      state.investWindowOpened = !state.investWindowOpened;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendInvestSTOTransaction.pending, (state) => {
      state.response = [];
      state.loading = true;
    });
    builder.addCase(
      sendInvestSTOTransaction.fulfilled,
      (state, { payload }) => {
        console.log("===> InvestTransaction Response : ", payload);
        _updateNotification(payload);
        state.response = payload.status;
        state.loading = false;
      }
    );
    builder.addCase(sendInvestSTOTransaction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const investSTOStateSelector = (state: any) => state.ivestSto;
export const { changeInvestWindowState } = ivestStoSlice.actions;
export default ivestStoSlice.reducer;
