import { useEffect, useState } from "react";
import WalletCard from "./WalletCard";
import SecurityTokensCard from "./SecurityTokensCard";
import MyAssets from "./MyAssetsTable";
import TokensExchangeTable from "./TokenExxhangeTable";

const MainPage = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className="w-full h-full lg:h-[calc(100vh+80px)] bg-[url('assets/DashboardBg.webp')]  bg-no-repeat bg-cover md:px-10 px-4 py-1">
      <div className="w-full h-full grid lg:grid-cols-[1fr,2fr] lg:gap-4 gap-6">
        {/* left side */}
        <div className="w-full h-full flex flex-col items-center justify-start gap-6">
          <WalletCard showButtons={true} />
          <SecurityTokensCard />
        </div>
        {/* right side */}
        {isClient && (
          <div className="w-full h-full flex flex-col items-center justify-start gap-6 font-manrope">
            <MyAssets />
            <TokensExchangeTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainPage;
