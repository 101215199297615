import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphClient";
import { gql } from "@apollo/client";
import { ISTo } from "./types/sto";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

interface IStoState {
  response?: ISTo;
  loading: boolean;
  error: any;
}

const initialState: IStoState = {
  response: {} as ISTo,
  loading: true,
  error: {},
};

export const fetchActiveStos = createAsyncThunk(
  "graph/activestos",
  async () => {
    const query_sto = gql`
      {
        stos(
          filter: {
            creatorId: {
              equalTo: "${TOKEN_ISSUER_DID}"
            }
          }
          orderBy: CREATED_BLOCK_ID_DESC
        ) {
          nodes {
            id
            stoId
            offeringAsset {
              ticker
            }
            name
            status
            start
            end
            tiers
            minimumInvestment
            createdAt
            createdBlockId
          }
        }
      }
    `;
    const q = { query: query_sto };

    try {
      const { data } = await apolloClient.query(q);
      console.log("STO Graph Data => ", data);
      return data;
    } catch (error) {
      console.log("STO Graph Error  => ");
      console.log(error);
      console.log("===========================");
      return {};
    }
  }
);

export const activeStoSlice = createSlice({
  name: "activestos",
  initialState,
  reducers: {
    filterStosByStatus: (state, action) => {
      if (state.response && state.response.stos && state.response.stos.nodes) {
        state.response.stos.nodes = state.response.stos.nodes.filter((sto) => {
          const endDate = new Date(sto.end);
          const currentDate = new Date();
          return sto.status === action.payload && endDate > currentDate;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveStos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActiveStos.fulfilled, (state, { payload }) => {
      state.response = payload;
      console.log("fetchSubgraphData.fulfilled => ", payload);
      console.log("Merge With Tokens metadata ...");
      state.loading = false;
    });
    builder.addCase(fetchActiveStos.rejected, (state, action) => {
      console.log("fetchSubgraphData.rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const activeStoSliceSelector = (state: any) => state.activeStos;
export const { filterStosByStatus } = activeStoSlice.actions;
export default activeStoSlice.reducer;
