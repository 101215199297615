import { useContext, useEffect, useState } from "react";
import { Card, Group, Text, Table, Alert, LoadingOverlay } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import Context from "../../context/Context";
import { IUser } from "../../types";

import {
  useAppDispatch,
  useAppSelector,
  fetchSubgraphData,
} from "../../app/index";
import SellTokenModal from "../Exchange/SellTokenModal";
import { formatNumber } from "../../helper";
import AssetComponent from "../Components/AssetComponent";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

export default function MyAssetsWidget() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const graphData = useAppSelector((state) => state.graph);

  useEffect(() => {
    const { user } = context.globalstate;
    const userAsIUser: IUser = user as IUser;

    dispatch(
      fetchSubgraphData({
        userDid: userAsIUser.cdd,
        adminDid: TOKEN_ISSUER_DID,
      })
    );
  }, [value]);

  if (TOKEN_ISSUER_DID === "") {
    return (
      <Card withBorder shadow="sm" radius="md" mt={20}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text weight={900}>Spot</Text>
          </Group>
        </Card.Section>
        <Group position="apart" mt="md" mb="xs">
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error Loading Assets"
            color="red"
          >
            Sorry, it seems that an error occurred. We were unable to find a
            Token Issuer Address. Please contact the System Administrator for
            assistance.
          </Alert>
        </Group>
      </Card>
    );
  }

  const rows = graphData.response?.assetHolders.nodes.map((asset, key) => (
    <tr key={asset.ticker}>
      <td>
        {/* <Anchor
          color={"dark"}
          component="button"
          type="button"
          onClick={() => navigate(`/token/${asset.ticker}`)}
        >
          {asset.ticker}
        </Anchor> */}

        <AssetComponent ticker={asset.ticker} />
      </td>

      <td>{formatNumber(asset.amount / 1000000)}</td>
      <td>
        <SellTokenModal {...asset} />
      </td>
      {/* <td>
        <Anchor href="#" target="_blank">
          Transactions
        </Anchor>
      </td> */}
    </tr>
  ));

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={graphData.loading} overlayBlur={0.1} />

      <Card withBorder shadow="sm" radius="md" mt={20}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text weight={900}>Spot</Text>

            {/* <Group mt="xs">
              <Tooltip label="Reload">
                <ActionIcon variant="transparent">
                  <IconRefresh />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Open">
                <ActionIcon variant="transparent">
                  <IconExternalLink />
                </ActionIcon>
              </Tooltip>
            </Group> */}
          </Group>
        </Card.Section>

        <Text mt="sm" color="dimmed" size="sm">
          <Text component="span" inherit color="blue"></Text>
        </Text>

        <Group position="apart" mt="md" mb="xs">
          <Table>
            {/* <caption>A list of the assets you've invested in:</caption> */}
            <thead>
              <tr>
                <th>Asset Ticker</th>
                <th>Balance</th>
                <th></th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Group>

        <Card.Section inheritPadding mt="sm" pb="md"></Card.Section>
      </Card>
    </div>
  );
}
