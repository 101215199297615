import Divider from "../Shared/Divider";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  fetchInvestment,
} from "../../app/index";
import { useLoggedUser } from "../../helper";
import format from "date-fns/format";
import MantineTable from "../Shared/MantineTable";
import AssetComponent from "../Components/AssetComponent";

export default function StoTransactionsNew() {
  const loggedUser = useLoggedUser();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const stoGraph = useAppSelector((state) => state.stoinvestments);

  useEffect(() => {
    dispatch(fetchInvestment({ userDid: loggedUser.cdd }));
  }, [value]);

  const rows = stoGraph.response.investments?.nodes.map((node, index) => (
    <div
      key={index}
      className="w-full flex flex-col items-center justify-start gap-2"
    >
      <tr className="w-full grid sm:grid-cols-[1.2fr,1.5fr,1fr,1fr,1fr] grid-cols-[1.1fr,2fr,1fr] place-items-start">
        <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
          {format(new Date(node.createdAt), "dd-MMM-yy")}
        </td>
        <td
          className={`py-[2px] px-[10px] rounded text-xs  items-center justify-center  font-semibold`}
        >
          <AssetComponent ticker={node.offeringToken} />
        </td>
        <td className="w-full sm:flex hidden items-center justify-start gap-2 text-black text-xs font-[700]">
          {" "}
          {node.offeringTokenAmount / 1000000}
        </td>
        <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
          {node.raiseTokenAmount / 1000000} &nbsp;
          {node.raiseToken}
        </td>
      </tr>
      {<Divider />}
    </div>
  ));

  return (
    <>
      <MantineTable title="Investment History" setting>
        <div className="w-full overflow-auto flex flex-col items-center justify-start gap-4 px-8">
          <thead className="w-full">
            <tr className=" w-full grid sm:grid-cols-[1.2fr,1.5fr,1fr,1fr,1fr] grid-cols-[1.1fr,2fr,1fr] place-items-start">
              <th className="text-xs text-gray-main font-semibold font-manrope">
                Date
              </th>
              <th className="text-xs  text-gray-main font-semibold font-manrope">
                Asset
              </th>
              <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
                Amount
              </th>
              <th className="text-xs  text-gray-main font-semibold font-manrope">
                Paid
              </th>
            </tr>
          </thead>
          <tbody className="w-full h-full overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar">
            {rows}
          </tbody>
        </div>
      </MantineTable>
    </>
  );
}
