import { IAssetsMetadata, ITokenMetadata } from "../types/tokensMetadata";

import SPOTT from "../../assets/tickers/1.png";
import TYTYTY from "../../assets/tickers/2.png";
import PLANET01 from "../../assets/tickers/3.png";
import TYN01 from "../../assets/tickers/4.png";
import SOMETECH from "../../assets/tickers/5.png";
import TESTCORP from "../../assets/tickers/6.png";
import NEXUSCORP from "../../assets/tickers/7.png";
import SOLARATECH from "../../assets/tickers/8.png";
import TEST_TOKEN_4 from "../../assets/tickers/9.png";
import MLST from "../../assets/tickers/11.jpg";
import PEAGLE from "../../assets/tickers/12.jpg";
import ANDI1 from "../../assets/tickers/20.png";
import RESIDUAL1 from "../../assets/tickers/19.png";

import NONAME from "../../assets/tickers/0.png";

const assetsArray: ITokenMetadata[] = [
  {
    desc: "RESIDUAL1 Description ...",
    ticker: "RESIDUAL1",
    name: "Spott",
    type: "Fund",
    term_month: "12",
    average_rate: "0.1",
    logo: RESIDUAL1,
  },
  {
    desc: "Andi Token Description ...",
    ticker: "ANDI1",
    name: "Spott",
    type: "Fund",
    term_month: "12",
    average_rate: "0.1",
    logo: ANDI1,
  },
  {
    desc: "The new tech company",
    ticker: "SPOTT",
    name: "Spott",
    type: "Fund",
    term_month: "12",
    average_rate: "0.1",
    logo: SPOTT,
  },
  {
    desc: "The test test company",
    ticker: "TYTYTY",
    name: "Test Test",
    type: "Fund",
    term_month: "12",
    average_rate: "0.1",
    logo: TYTYTY,
  },
  {
    desc: "The planet ark company",
    ticker: "PLANET01",
    name: "PLANET ARK Seed 1",
    type: "EquityPreferred",
    term_month: "12",
    average_rate: "0.1",
    logo: PLANET01,
  },
  {
    desc: "The tyndall capital company description",
    ticker: "TYN01",
    name: "Tyndall Capital Seed 1",
    type: "EquityCommon",
    term_month: "12",
    average_rate: "0.1",
    logo: TYN01,
  },
  {
    desc: "some tech company description",
    ticker: "SOMETECH",
    name: "The new tech company",
    type: "EquityPreferred",
    term_month: "12",
    average_rate: "0.1",
    logo: SOMETECH,
  },
  {
    desc: "Test Corp Description",
    ticker: "TESTCORP",
    name: "The New Corp",
    type: "FixedIncome",
    term_month: "12",
    average_rate: "0.1",
    logo: TESTCORP,
  },
  {
    desc: "The nexus corp description ...",
    ticker: "NEXUSCORP",
    name: "NEXUSCORP",
    type: "Commodity",
    term_month: "12",
    average_rate: "0.1",
    logo: NEXUSCORP,
  },
  {
    desc: "Solar energy solutions description",
    ticker: "SOLARATECH",
    name: "Solar energy solutions",
    type: "Fund",
    term_month: "12",
    average_rate: "0.1",
    logo: SOLARATECH,
  },
  {
    desc: "Test Token 4 description",
    ticker: "TEST-TOKEN-4",
    name: "Test Security Token",
    type: "EquityCommon",
    term_month: "12",
    average_rate: "0.1",
    logo: TEST_TOKEN_4,
  },
  {
    desc: "MLST Description ...",
    ticker: "MLST",
    name: "Marketlend Test Token",
    type: "EquityPreferred",
    term_month: "12",
    average_rate: "0.1",
    logo: MLST,
  },
  {
    desc: "PEAGLE",
    ticker: "MLST",
    name: "Peagle Token Test",
    type: "EquityCommon",
    term_month: "12",
    average_rate: "0.1",
    logo: PEAGLE,
  },
  {
    desc: "Peagle Token Description",
    ticker: "EXELSYS-TEST",
    name: "eXelsys",
    type: "EquityCommon",
    term_month: "12",
    average_rate: "0.1",
    logo: NONAME,
  },
  {
    desc: "Test Token 3 description",
    ticker: "TEST-TOKEN-3",
    name: "Real Assets Token",
    type: "Commodity",
    term_month: "12",
    average_rate: "0.1",
    logo: NONAME,
  },
  {
    desc: "Marketlend Stable Australian Dolar Token",
    ticker: "USDT",
    name: "USDT Stable Coin",
    type: "StableCoin",
    term_month: "12",
    average_rate: "0.1",
    logo: NONAME,
  },
];

// export const assets_metadata: Map<string, ITokenMetadata> = new Map(
//   assetsArray.map((asset) => [asset.ticker, asset])
// );

export const assets_metadata: IAssetsMetadata = assetsArray.reduce(
  (acc, asset) => {
    acc[asset.ticker] = asset;
    return acc;
  },
  {} as IAssetsMetadata
);
