import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Center,
  Code,
  createStyles,
  getStylesRef,
  Navbar,
  rem,
} from "@mantine/core";
import {
  IconLogout,
  IconSettings,
  IconSwitchHorizontal,
} from "@tabler/icons-react";
import Context from "../../context/Context";
import { linksData } from "./NavbarLinks";
import { useViewportSize } from "@mantine/hooks";

import {
  useAppDispatch,
  useAppSelector,
  openNavbar,
  closeNavbar,
} from "../../app/index";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  title: {
    textTransform: "uppercase",
    letterSpacing: rem(-0.25),
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.md,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 700,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.md,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "outline",
        color: theme.black,
      }).background,
      color: theme.fn.variant({ variant: "outline", color: theme.black }).color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "outline", color: theme.black })
          .color,
      },
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingTop: theme.spacing.md,
  },
}));

export default function NavbarExpanded() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("/home");
  const location = useLocation();
  const params = useParams();
  const context = useContext(Context);

  const { height, width } = useViewportSize();

  const dispatch = useAppDispatch();
  const appSettings = useAppSelector((state) => state.appsettings);

  useEffect(() => {
    setActive(location.pathname);
  }, []);

  const links = linksData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.link === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        console.log("NavbarExpanded.tsx onClick");
        dispatch(closeNavbar());
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </Link>
  ));

  return (
    <Navbar
      // width={{ sm: 250 }}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!appSettings.navigationOpened}
      width={{ sm: 270, lg: 280 }}
    >
      <Navbar.Section grow>{links}</Navbar.Section>

      {/* <Center inline>
        <Code sx={{ fontWeight: 700 }}>
          {appSettings.appVersion} {""}
          W: {width}, H: {height}
        </Code>
      </Center> */}
      <br />

      <Navbar.Section className={classes.footer}>
        <Link className={classes.link} to="/settings">
          <IconSettings className={classes.linkIcon} stroke={1.5} />
          Settings{" "}
        </Link>

        <a
          href="#"
          className={classes.link}
          onClick={(event) => {
            context.handlers.logout();
            event.preventDefault();
          }}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}
