import React, { useContext, useState } from "react";
import { Card, LoadingOverlay, Anchor, Divider, Image } from "@mantine/core";
import NONAME from "../../assets/tickers/0.png";
import {
  useAppSelector,
  selecAssetsWithActiveSto,
  selecAssetsWithActiveSto_func,
} from "../../app/index";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { IUser } from "src/types";
import Context from "../../context/Context";
import { fetchSubgraphData } from "@appstore/index";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

const SecurityTokensCard = () => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const graphData = useAppSelector((state) => state.graph);
  const metadata = useAppSelector((state) => state.tokensmetadata);

  const [value, setValue] = useState("");

  useEffect(() => {
    const { user } = context.globalstate;
    const userAsIUser: IUser = user as IUser;
    console.log("TODO: Clean up this code");
  }, [value]);

  if (graphData.loading) {
    return (
      <Card
        shadow="lg"
        padding="xl"
        radius="lg"
        className="bg-white w-full overflow-hidden"
      >
        <div className="w-full h-full flex flex-col items-start justify-start gap-6">
          <div className="w-full flex items-center justify-start py-4 border-b-[2px] border-b-gray-300">
            <h2 className="text-base text-black font-bold font-manrope">
              Security Tokens
            </h2>
          </div>
          <LoadingOverlay visible={true} overlayBlur={0.1} />
        </div>
      </Card>
    );
  }

  const items = graphData.response?.assets.nodes.map((item) => (
    <div className="w-full flex flex-col items-start justify-start gap-2">
      <h2 className="text-base text-black font-bold font-manrope">EW1</h2>
      <p className="text-xs text-gray-main font-medium font-manrope">
        Electricity Wizard
      </p>
    </div>
  ));

  const items2 = selecAssetsWithActiveSto_func(graphData).map((asset) => (
    <div className="w-full flex flex-col items-start justify-start gap-2">
      <h2 className="text-base text-black font-bold font-manrope">
        <Link to={`/token/${asset.ticker}`}>{asset.ticker}</Link>
      </h2>
      <p className="text-xs text-gray-main font-medium font-manrope">
        {asset.name}
      </p>
    </div>
  ));

  const items3 = selecAssetsWithActiveSto_func(graphData).map((asset) => (
    <li className="flex w-full py-4 first:pt-0 last:pb-0">
      {metadata.assets[asset.ticker] && (
        <img
          className="h-10 w-10 rounded-full"
          src={metadata.assets[asset.ticker]?.logo}
          alt=""
        />
      )}

      {!metadata.assets[asset.ticker] && (
        <img className="h-10 w-10 rounded-full" src={NONAME} alt="" />
      )}

      <div className="ml-3 overflow-hidden">
        <Link to={`/token/${asset.ticker}`}>
          <p className="text-sm font-medium text-slate-900">{asset.ticker}</p>
          <p className="text-sm text-slate-500 truncate">{asset.name}</p>
        </Link>
      </div>
    </li>
  ));

  return (
    <Card
      shadow="lg"
      padding="xl"
      radius="lg"
      className="bg-white w-full overflow-hidden"
    >
      <div className="w-full h-full flex flex-col items-start justify-start gap-6">
        <div className="w-full flex items-center justify-start py-4 border-b-[2px] border-b-gray-300">
          <h2 className="text-base text-black font-bold font-manrope">
            Security Tokens
          </h2>
        </div>
        {/* {items2} */}

        <ul
          role="list"
          className="p-6 divide-y divide-slate-200 w-full flex flex-col items-start justify-start gap-2"
        >
          {items3}
        </ul>
      </div>
    </Card>
  );
};

export default SecurityTokensCard;
