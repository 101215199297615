import {
  createAction,
  createSlice,
  createAsyncThunk,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { IToken } from "./types/index";
import { AxiosResponse } from "axios";
import _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

export interface ITokenState {
  response: IToken[];
  selectedTiker: string | null;
  selectedToken?: IToken | null;
  loading: boolean;
  error: object;
}

const initialState: ITokenState = {
  response: [],
  selectedTiker: null,
  selectedToken: null,
  loading: true,
  error: {},
};

export const fetchUserTokens = createAsyncThunk(
  "user/tokens",
  async (service: AxiosResponse) => {
    try {
      const { data: response } = await service.request.get(
        `${API_URL}/user/tokens/getusertokens`
      );
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const tokenSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    selectToken: (state, action) => {
      console.log("===> Select Token Action => Payload : ", action);
      state.selectedTiker = action.payload;
      let selectedToken = _.find(state.response, { tiker: action.payload });
      state.selectedToken = selectedToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserTokens.pending, (state) => {
      state.response = [];
      state.loading = true;
    });
    builder.addCase(fetchUserTokens.fulfilled, (state, { payload }) => {
      state.response = _.orderBy(payload.data, "balance", ["desc"]);
      state.loading = false;
    });
    builder.addCase(fetchUserTokens.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { selectToken } = tokenSlice.actions;
export const tokenStateSelector = (state: any) => state.tokens;
export default tokenSlice.reducer;
