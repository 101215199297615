import { createSlice } from "@reduxjs/toolkit";

export interface TransactionPageState {
  activePage: string;
  error: object;
}

const initialState: TransactionPageState = {
  activePage: "deposit",
  error: {},
};

export const transactionPageSlice = createSlice({
  name: "trpage",
  initialState,
  reducers: {
    setActiveTransactionPage: (state, action) => {
      console.log("===> Transaction Page Selected : ", action);
      state.activePage = action.payload;
    },
  },
});

export const { setActiveTransactionPage } = transactionPageSlice.actions;
export const transaxctionPageStateSelector = (state: any) =>
  state.transactionpage;
export default transactionPageSlice.reducer;
