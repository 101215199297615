import { useEffect, useState } from "react";
import { Group, Anchor, Avatar, Image } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { extractLetters } from "../../helper";
import { useAppSelector } from "../../app/index";
import NONAME from "../../assets/tickers/0.png";

interface Iprops {
  ticker: string;
}

export default function AssetComponent({ ticker }: Iprops) {
  // const navigate = useNavigate();
  const metadata = useAppSelector((state) => state.tokensmetadata);
  const tokenObject = metadata.assets[ticker];

  return (
    <>
      {tokenObject?.logo && (
        <>
          <div className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <div className="relative h-[23px] w-[23px] rounded-full">
              <img
                src={tokenObject.logo}
                className="w-full h-full object-cover rounded-full"
                alt=""
              />
            </div>
            <Link to={`/token/${ticker}`}>{tokenObject.ticker}</Link>
          </div>
        </>
      )}

      {!tokenObject?.logo && (
        <>
          <div className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <div className="relative h-[23px] w-[23px] rounded-full">
              <img
                src={NONAME}
                className="w-full h-full object-cover rounded-full"
                alt=""
              />
            </div>
            <Link to={`/token/${ticker}`}>{ticker}</Link>
          </div>
        </>
      )}
    </>
  );
}
