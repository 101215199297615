import {
  Button,
  Drawer,
  Group,
  LoadingOverlay,
  NativeSelect,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { useState, useEffect, useContext } from "react";
import { showNotification, updateNotification } from "@mantine/notifications";

import {
  useAppSelector,
  useAppDispatch,
  closeDepositWindow,
  changeWithdrawWindowState,
} from "../../app/index";
import Context from "../../context/Context";
import { useForm } from "@mantine/form";
import { IconCheck } from "@tabler/icons-react";

const API_URL = process.env.REACT_APP_API_URL;

const data = [
  { value: "aud", label: "🇦🇺 AUD" },
  { value: "usd", label: "🇺🇸 USD" },
];

export default function Withdraw() {
  const dispatch = useAppDispatch();
  const context = useContext(Context);
  const [processing, setProcessing] = useState(false);
  const { service } = context.globalstate;
  const { withdrawWindowOpened } = useAppSelector((state) => state.maei);
  const userState = useAppSelector((state) => state.user);

  const form = useForm({
    initialValues: {
      currency: "AUD",
      amount: "",
      transactionType: 2,
    },
  });

  type FormValues = typeof form.values;

  // TODO: Move to REDUX
  const handleSubmit = async (values: FormValues) => {
    setProcessing(true);

    showNotification({
      color: "teal",
      id: "load-data",
      loading: true,
      title: "Processing Transaction",
      message: "Please wait while we are processing your transaction",
      autoClose: false,
    });

    const response = await service.request.post(
      `${API_URL}/user/funds`,
      values
    );

    console.log("Withdrawal Transaction Response ==> ", response);

    setTimeout(() => {
      setProcessing(false);
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Transaction Completed",
        message:
          "Please check your transaction status in your transaction history page",
        icon: <IconCheck size={16} />,
        autoClose: 3000,
        onClose: () => {
          dispatch(closeDepositWindow(1));
        },
      });
    }, 3000);
  };

  function handleClose() {
    dispatch(changeWithdrawWindowState());
  }

  const select = (
    <NativeSelect
      data={data}
      size="md"
      {...form.getInputProps("currency")}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }}
    />
  );

  useEffect(() => {}, []);

  function Title() {
    return <h2>Withdraw Funds</h2>;
  }

  return (
    <Drawer
      opened={withdrawWindowOpened}
      onClose={() => handleClose()}
      padding="lg"
      title={<Title />}
      size="md"
      position="right"
      withOverlay={true}
      classNames={{ title: "drawer-title" }}
    >
      <LoadingOverlay visible={processing} />

      <SimpleGrid cols={1} spacing="md" verticalSpacing="md">
        <div>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              type="number"
              placeholder="1000"
              label="Withdrawal amount"
              rightSection={select}
              rightSectionWidth={113}
              size="md"
              {...form.getInputProps("amount")}
            />

            <br />

            <div className="shadow-md p-6">
              <p className="line-clamp-3 md:line-clamp-4"></p>

              <p className="text-base/loose">
                You have {userState.blackBalance} Maei, equivalent to{" "}
                {userState.blackBalance} AUD that is uncommitted and may be
                withdrawn at any time.
              </p>

              <p className="line-clamp-3 md:line-clamp-4 mt-4">
                By requesting a withdrawal you will be sending a request for the
                conversion and transfer of Maei from the Investment Platform to
                AUD and into your Nominated bank account as listed in your
                settings.
              </p>
            </div>

            <Group position="apart" grow spacing="xl" mt={20}>
              <Button
                type="submit"
                color="dark"
                size="md"
                classNames={{ root: "bg-brand-main" }}
              >
                Request Withdrawal
              </Button>
            </Group>
          </form>
        </div>
      </SimpleGrid>

      <br />
    </Drawer>
  );
}
