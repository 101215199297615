import { createSlice } from "@reduxjs/toolkit";

export interface INavbarSliceState {
  activeNavbar: string;
  error: object;
}

const initialState: INavbarSliceState = {
  activeNavbar: "home",
  error: {},
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setActiveNav: (state, action) => {
      console.log("===> Navbar Action Payload : ", action);
      state.activeNavbar = action.payload;
    },
  },
});

export const { setActiveNav } = navbarSlice.actions;
export const navbarStateSelector = (state: any) => state.navbar;
export default navbarSlice.reducer;
