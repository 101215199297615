import { Component } from "react";
import Context from "./Context";
import Service from "./WrappedAxious";

const API_URL = process.env.REACT_APP_API_URL;

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: new Service(), // Dont understand why we need class intialisa
      user: null,
      twoFaEnabled: false,
      jwtToken: null,
      sid: null,
      isLoggedIn: false,

      accountAddress: null,

      // smallScreen: false, // it might will be depricated
      // navbarHidden: true, // very tricky, it is used to close navbar on mobile
    };
  }

  setJwtToken = async (_data) => {
    const { token, success, data: user } = _data;
    this.setState({ jwtToken: token });
    this.setState({ isLoggedIn: success });
    this.setState({ user: user });
    // this.setState({ twoFaEnabled : user.autyenabled })
    this.setState({ service: new Service(token) });
  };

  logout = () => {
    this.setState({ jwtToken: null });
    this.setState({ isLoggedIn: false });
  };

  login = async (username, password) => {
    const loginData = {
      username: username,
      password: password,
    };

    // const response = await
    //     axios.post(`${API_URL}/user/login`, loginData)
    //         .catch(function (error) {
    //             console.log('login error : ', error);
    //                 return false;
    // });

    const response = await this.state.service.request.post(
      `${API_URL}/user`,
      loginData
    );

    const { data } = response;

    if (data && response.status === 200) {
      await this.setJwtToken(data);
    }
    return response;
  };

  // setScreenSize = async (isSmallScreen) => {
  //   this.setState({ smallScreen: isSmallScreen });
  // };

  // setNavbarHidden = async () => {
  //   this.setState({ navbarHidden: !this.state.navbarHidden });
  // };

  async componentDidMount() {
    console.log("APP PROVIDER DID MOUNT ... ");

    // console.log(
    //   "MOCK USER DATA : TODO: Do not use mock user data in production "
    // );

    // const mock_user = {
    //   success: true,
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjEsInVzZXJuYW1lIjoiYWNoaWtvM0BnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJhdXR5ZW5hYmxlZCI6ZmFsc2UsImRpZCI6IjB4Nzg4OGY4N2RlYjRlZWNlMmM0ZjJjMjg4YTlkZTVhMDhhMWU5ZGI3M2JhOTIwNTY1MWUwODM0NmY0NWI0OGM5MiIsImlhdCI6MTY3NzU5MjcxMSwiZXhwIjoxNjc3Njc5MTExfQ.dWW9kXKEM6DvP7NvjptQKFKXf3gcSSiX6L_CYo_J-SY",
    //   data: {
    //     username: "achiko3@gmail.com",
    //     role: "admin",
    //     autyenabled: false,
    //     accountaddress: "5GvERiYwLQa9euoyViaEoiwNx7r6JCEG7H849Z7FJ92JmeRD",
    //     cdd: "0x7888f87deb4eece2c4f2c288a9de5a08a1e9db73ba9205651e08346f45b48c92",
    //   },
    // };

    // this.setJwtToken(mock_user);
  }

  render() {
    const handlers = {
      getFunds: this.getFunds,
      login: this.login,
      logout: this.logout,
      // setScreenSize: this.setScreenSize,
      // setNavbarHidden: this.setNavbarHidden,
    };
    return (
      <Context.Provider value={{ globalstate: this.state, handlers: handlers }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Provider;
