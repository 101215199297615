import {
  Badge,
  Button,
  Card,
  Center,
  createStyles,
  Group,
  Progress,
  rem,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IOfferingDetails } from "../../app/types/sto";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { IconCalendarDue } from "@tabler/icons-react";
import InvestSTODrawer from "./InvestSTODrawer";

import {
  useAppSelector,
  useAppDispatch,
  changeInvestWindowState,
} from "../../app/index";
import { formatNumber } from "../../helper";

interface StoCardProps extends IOfferingDetails {
  test: string;
}

interface Indicator {
  overdue: boolean;
  daysLeft: number;
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[5],
  },
}));

const calcPercent = (totalAmount: number, totalRemaining: number) => {
  if (totalAmount === totalRemaining) return 0.0;
  let _percent = ((totalAmount - totalRemaining) / totalAmount) * 100;
  return parseFloat(_percent.toFixed(2));
};

export default function StoCard(props: StoCardProps) {
  const dispatch = useAppDispatch();
  const { classes, theme } = useStyles();

  const [daysIndicator, setDateIndicator] = useState<Indicator>({
    overdue: false,
    daysLeft: 0,
  });

  const [daysLeft, setDaysLeft] = useState("");
  const [percent, serPercent] = useState(0);

  useEffect(() => {
    const _daysLeft = formatDistance(new Date(), new Date(props.end));
    setDaysLeft(_daysLeft);
    const _percent = (props.totalRemaining / props.totalAmount) * 100;
    serPercent(_percent);
  }, []);

  const tiers = props.tiers?.map((tier: any, key: number) => (
    <>
      {" "}
      {tier.price} {props.raisingCurrency}{" "}
    </>
  ));

  return (
    <div>
      <InvestSTODrawer />
      <Card withBorder shadow="sm" radius="md" mt={20}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text weight={800}>{props.name}</Text>

            {props.status?.timing !== "Expired" && (
              <Badge color="green">{daysLeft} left </Badge>
            )}

            {props.status?.timing === "Expired" && (
              <Badge color="gray" variant="outline">
                {props.status?.timing}
              </Badge>
            )}
          </Group>
        </Card.Section>

        <Group position="apart">
          <Text fz="lx" fw={500} mt="md">
            Minimum Investment Amount: {formatNumber(props.minInvestment)} Maei
          </Text>

          {/* {tiers} */}

          <Badge color={"green"} variant="outline">
            {props.status?.sale}
          </Badge>
        </Group>

        <Group spacing={8} mb={-8} mt={15}>
          {/* <Badge color={"gray"} variant="outline">
            {props.status?.sale}
          </Badge> */}
          {/* <Badge color={"gray"} variant="outline">
            {props.status?.sale}
          </Badge> */}
        </Group>

        <Group position="apart" spacing={8} mb={8} mt={10}>
          <Center key={"start"} mt={10}>
            <IconCalendarDue
              size="1.05rem"
              className={classes.icon}
              stroke={1.5}
            />
            <Text size="md">
              {"Start Date: " + format(new Date(props.start), "dd-MMM-yy")}
            </Text>
          </Center>
          <Center key={"end"} mt={10}>
            <IconCalendarDue
              size="1.05rem"
              className={classes.icon}
              stroke={1.5}
            />
            <Text size="md">
              {"End Date: " + format(new Date(props.end), "dd-MMM-yy")}
            </Text>
          </Center>
        </Group>

        <Text c="dimmed" fz="sm" mt="md">
          Tokens sold{" "}
          <Text
            span
            fw={500}
            sx={(theme) => ({
              color: theme.colorScheme === "dark" ? theme.white : theme.black,
            })}
          >
            {props.totalAmount - props.totalRemaining}
          </Text>{" "}
          Of{" "}
          <Text
            span
            fw={500}
            sx={(theme) => ({
              color: theme.colorScheme === "dark" ? theme.white : theme.black,
            })}
          >
            {props.totalAmount}
          </Text>{" "}
        </Text>

        <Progress
          label={calcPercent(props.totalAmount, props.totalRemaining) + "%"}
          size="xl"
          radius="xl"
          color={props.totalRemaining > 0 ? "teal" : "red"}
          value={calcPercent(props.totalAmount, props.totalRemaining)}
          mt={5}
        />

        <Stack mt={20}>
          <Group position="apart" spacing={30}>
            <div>
              <Text fz="md" fw={500} sx={{ lineHeight: 1 }}>
                Price: {tiers}
              </Text>
              {/* <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
                per token
              </Text> */}
            </div>

            <Tooltip label="Buy Token">
              <Button
                disabled={
                  props.status?.timing === "Expired" ||
                  props.status?.sale === "ClosedEarly"
                }
                classNames={{ root: "bg-brand-main" }}
                color="dark"
                // passing here STO object to the action, in the slice we will get it from the payload
                onClick={() => dispatch(changeInvestWindowState(props))}
              >
                Buy Token
              </Button>
            </Tooltip>
          </Group>
        </Stack>
      </Card>
    </div>
  );
}
