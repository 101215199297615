import { Aside, MediaQuery, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

export default function AsidePanel({ params = { sm: 5, lg: 400, xl: 440 } }) {
  // | "xs" | "sm" | "md" | "lg" | "xl"

  /*  
      xs	36em = 576
      sm	48em = 768
      md	62em = 992
      lg	75em = 1 200
      xl	88em = 1 408  // overrriden:  xl: "100em", see index.tsx in src
  */

  const { height, width } = useViewportSize();

  if (width > 1920 && width <= 2560) {
    return (
      <Aside
        withBorder={false}
        p="md"
        hiddenBreakpoint="sm"
        width={{ sm: 270, lg: 500, xl: 1000 }}
      >
        <div>
          <h1></h1>
        </div>
      </Aside>
    );
  }

  if (width > 2560) {
    return (
      <Aside
        withBorder={false}
        p="md"
        hiddenBreakpoint="sm"
        width={{ sm: 270, lg: 500, xl: 1500 }}
      >
        <div>
          <h1></h1>
        </div>
      </Aside>
    );
  }

  return (
    <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
      <Aside withBorder={false} p="md" hiddenBreakpoint="sm" width={params}>
        <div></div>
      </Aside>
    </MediaQuery>
  );
}
