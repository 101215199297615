import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { IState } from "./types/index";
import { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchBlackBalance = createAsyncThunk(
  "user/polymesh/blackbalance",
  async (service: AxiosResponse) => {
    try {
      const { data: response } = await service.request.get(
        `${API_URL}/user/balance`
      );
      return response.data.blackBalance;
    } catch (error) {
      return error;
    }
  }
);

const initialState: IState = {
  blackBalance: 0.0,
  loading: true,
  error: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlackBalance.pending, (state) => {
      state.blackBalance = 0;
      state.loading = true;
    });
    builder.addCase(fetchBlackBalance.fulfilled, (state, { payload }) => {
      state.blackBalance = payload;
      state.loading = false;
    });
    builder.addCase(fetchBlackBalance.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const userStateSelector = (state: any) => state.user;
export default userSlice.reducer;
