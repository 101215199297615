import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import MantineTable from "../Shared/MantineTable";
import Divider from "../Shared/Divider";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

import {
  useAppDispatch,
  useAppSelector,
  fetchTransactions,
} from "../../app/index";

import format from "date-fns/format";
import { formatNumber } from "../../helper";

enum TrType {
  DEPOSIT = 1,
  WITHDRAWAL = 2,
}

enum Status {
  PENDING = "P",
  CONFIRMED = "C",
  REJECTED = "R",
}

const DepositHistory = () => {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const transactions = useAppSelector((state) => state.transactions);

  useEffect(() => {
    const { service } = context.globalstate;
    dispatch(fetchTransactions(service));
  }, [value]);

  const rows = transactions.response.map((item, index) => (
    <div
      key={index}
      className="w-full flex flex-col items-center justify-start gap-2"
    >
      <tr className="w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr,1fr] grid-cols-[1.5fr,1.5fr,1fr] place-items-start">
        <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
          {format(new Date(item.created_at), "dd-MMM-yy")}
        </td>
        <td
          className={`py-[2px] px-[10px] rounded ${
            item.transaction_status == Status.PENDING
              ? "bg-[#FFF0D8] text-[#ED9E1F]"
              : item.transaction_status == Status.CONFIRMED
              ? "bg-[#D4FFE6] text-[#27AE60]"
              : "bg-[#FFDDDD] text-[#EB5757]"
          } text-xs sm:flex hidden items-center justify-center  font-semibold`}
        >
          {item.transaction_status === Status.PENDING && "Pending"}
          {item.transaction_status === Status.CONFIRMED && "Confirmed"}
          {item.transaction_status === Status.REJECTED && "Rejected"}
        </td>
        <td className="w-full  items-center justify-start gap-2">
          <div className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <span
              className={`rounded-full h-[18px] w-[18px] flex items-center justify-center ${
                item.transaction_type == TrType.DEPOSIT
                  ? "bg-[#CFFFD4]"
                  : "bg-[#FFE5E5]"
              }`}
            >
              {item.transaction_type == TrType.DEPOSIT ? (
                <BsArrowDown className="text-[10px] text-[#0BD01F]" />
              ) : (
                <BsArrowUp className="text-[10px] text-[Red]" />
              )}
            </span>
            <span className="text-black text-xs font-semibold">
              {item.transaction_type == TrType.DEPOSIT
                ? "Deposit"
                : "Withdrawal"}
            </span>
          </div>
        </td>
        <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
          {formatNumber(parseFloat(item.amount))}
        </td>
      </tr>
      {<Divider />}
    </div>
  ));

  return (
    <MantineTable title="Deposit & Withdrawal History" setting>
      <div className="w-full overflow-auto flex flex-col items-center justify-start gap-4 px-8">
        <thead className="w-full">
          <tr className="w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr,1fr] grid-cols-[1.5fr,1.5fr,1fr] place-items-start">
            <th className="text-xs text-gray-main font-semibold font-manrope">
              Date
            </th>
            <th className="text-xs sm:flex hidden text-gray-main font-semibold font-manrope">
              Status
            </th>
            <th className="text-xs  text-gray-main font-semibold font-manrope">
              Type
            </th>
            <th className="text-xs text-gray-main font-semibold font-manrope">
              Value
            </th>
          </tr>
        </thead>
        <tbody className="w-full h-full overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar">
          {rows}
        </tbody>
      </div>
    </MantineTable>
  );
};

export default DepositHistory;
