import { useContext, useEffect, useState } from "react";
import { Card, Group, Text, Alert, Skeleton } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import Context from "../../context/Context";
import { IUser } from "../../types";
import {
  useAppDispatch,
  useAppSelector,
  fetchSubgraphData,
} from "../../app/index";
import SellTokenModal from "../Exchange/SellTokenModal";
import { formatNumber } from "../../helper";
import AssetComponent from "../Components/AssetComponent";

import MantineTable from "../Shared/MantineTable";
import Divider from "../Shared/Divider";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

const MyAssetsTable = () => {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");

  const graphData = useAppSelector((state) => state.graph);

  useEffect(() => {
    const { user } = context.globalstate;
    const userAsIUser: IUser = user as IUser;

    dispatch(
      fetchSubgraphData({
        userDid: userAsIUser.cdd,
        adminDid: TOKEN_ISSUER_DID,
      })
    );
  }, [value]);

  if (TOKEN_ISSUER_DID === "") {
    return (
      <Card withBorder shadow="sm" radius="md" mt={20}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text weight={900}>Spot</Text>
          </Group>
        </Card.Section>
        <Group position="apart" mt="md" mb="xs">
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error Loading Assets"
            color="red"
          >
            Sorry, it seems that an error occurred. We were unable to find a
            Token Issuer Address. Please contact the System Administrator for
            assistance.
          </Alert>
        </Group>
      </Card>
    );
  }

  if (graphData.loading) {
    return (
      <>
        <MantineTable title="My Assets">
          <div className="w-[99%] overflow-auto flex flex-col items-center justify-start gap-4">
            <thead className="w-full px-8">
              <tr className=" w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr] grid-cols-[.5fr,1fr,1fr] place-items-start">
                <th className="text-xs text-gray-main font-semibold font-manrope">
                  No
                </th>
                <th className="text-xs text-gray-main font-semibold font-manrope">
                  Asset Ticker
                </th>
                <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
                  Balance
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="w-full max-h-[200px] overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar px-8">
              <div className="w-full flex flex-col items-center justify-start gap-2">
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
                <Skeleton height={7} mt={3} radius="xl" />
              </div>
            </tbody>
          </div>
        </MantineTable>
      </>
    );
  }

  const rows = graphData.response?.assetHolders.nodes.map((asset, index) => (
    <div
      key={index}
      className="w-full flex flex-col items-center justify-start gap-2"
    >
      <tr className="w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr] grid-cols-[2fr,1fr] place-items-start">
        <td className="text-black text-xs font-semibold sm:inline-block hidden">
          {index + 1}
        </td>
        <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
          <AssetComponent ticker={asset.ticker} />
        </td>
        <td className="text-sm sm:inline-block hidden text-black font-semibold">
          {formatNumber(asset.amount / 1000000)}
        </td>
        <td className="w-full flex items-center justify-end">
          <SellTokenModal {...asset} />
        </td>
      </tr>
      {<Divider />}
    </div>
  ));

  return (
    <MantineTable title="My Assets">
      <div className="w-[99%] overflow-auto flex flex-col items-center justify-start gap-4">
        <thead className="w-full px-8">
          <tr className=" w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr] grid-cols-[2fr,1fr] place-items-start">
            <th className="text-xs text-gray-main font-semibold font-manrope">
              No
            </th>
            <th className="text-xs text-gray-main font-semibold font-manrope">
              Asset Ticker
            </th>
            <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
              Balance
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="w-full max-h-[200px] overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar px-8">
          {rows}
        </tbody>
      </div>
    </MantineTable>
  );
};

export default MyAssetsTable;
