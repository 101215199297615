import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { concat } from "lodash";

const GRAPH_ENDPOINT = process.env.REACT_APP_GRAPH_ENDPOINT || "";

const link = new HttpLink({
  uri: GRAPH_ENDPOINT,
  fetchOptions: {
    mode: "cors",
  },
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link,
  cache,
});
