import { useEffect, useState } from "react";
import {
  Card,
  Group,
  Text,
  SimpleGrid,
  CopyButton,
  ActionIcon,
  Tooltip,
  Code,
  Input,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useLoggedUser } from "../../helper";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

function truncateHash(hash: string): string {
  if (hash.length <= 24) {
    return hash;
  }

  const start = hash.substring(0, 9);
  const end = hash.substring(hash.length - 9);
  return `${start}...${end}`;
}

export default function AccountInfoCard() {
  const { height, width } = useViewportSize();
  const matches = useMediaQuery("(min-width: 56.25em)");
  const user = useLoggedUser();

  return (
    <Card shadow="sm" padding="xl" mt={20}>
      <Card.Section inheritPadding py="xs">
        <Text size={"xl"} weight={900}>
          Account Info{" "}
        </Text>
      </Card.Section>
      <Card.Section withBorder inheritPadding py="xs">
        <Input.Wrapper
          id="input-demo"
          // withAsterisk
          label="Account Address"
          // description="Please enter your credit card information, we need some money"
          // error="Your credit card expired"
        >
          <Input
            // icon={<IconBrandTwitter size="1rem" />}
            placeholder="*****"
            value={user.accountaddress}
            rightSection={
              // <Tooltip label="This is public" position="top-end" withArrow>
              <div>
                <CopyButton value={user.accountaddress} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? "Copied" : "Copy"}
                      withArrow
                      position="right"
                    >
                      <ActionIcon
                        color={copied ? "teal" : "gray"}
                        onClick={copy}
                      >
                        {copied ? (
                          <IconCheck size="1rem" />
                        ) : (
                          <IconCopy size="1rem" />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </div>
              // </Tooltip>
            }
          />
        </Input.Wrapper>

        <Input.Wrapper
          id="input-demo"
          // withAsterisk
          label="Decentralised identifier (DID)"
          // description="Please enter your credit card information, we need some money"
          // error="Your credit card expired"
        >
          <Input
            // icon={<IconBrandTwitter size="1rem" />}
            placeholder="*****"
            value={user.cdd}
            rightSection={
              // <Tooltip label="This is public" position="top-end" withArrow>
              <div>
                <CopyButton value={user.cdd} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? "Copied" : "Copy"}
                      withArrow
                      position="right"
                    >
                      <ActionIcon
                        color={copied ? "teal" : "gray"}
                        onClick={copy}
                      >
                        {copied ? (
                          <IconCheck size="1rem" />
                        ) : (
                          <IconCopy size="1rem" />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </div>
              // </Tooltip>
            }
          />
        </Input.Wrapper>
      </Card.Section>
    </Card>
  );

  return (
    <Card shadow="sm" padding="xl" mt={20}>
      {/* <div className="flex ...">
        <div className="w-1/2">w-1/2</div>
        <div className="w-1/2">w-1/2</div>
        <div className="w-1/2">w-1/2</div>
      </div> */}

      <Card.Section inheritPadding py="xs">
        <Group position="apart">
          <Text size={"xl"} weight={900}>
            Account Info{" "}
            {/* <Badge color={matches ? "teal" : "red"} variant="filled">
              Breakpoint {matches ? "matches" : "does not match"}
            </Badge> */}
          </Text>
        </Group>
      </Card.Section>
      <Card.Section withBorder inheritPadding py="xs">
        <SimpleGrid cols={1}>
          <div>
            <Group position="left" spacing="md">
              <Text>Address: </Text>
              {width <= 700 ? (
                <Code>{truncateHash(user.accountaddress)}</Code>
              ) : (
                <Code>{user.accountaddress}</Code>
              )}
              <CopyButton value={user.accountaddress} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy"}
                    withArrow
                    position="right"
                  >
                    <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                      {copied ? (
                        <IconCheck size="1rem" />
                      ) : (
                        <IconCopy size="1rem" />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </div>

          <div>
            <Group position="left" spacing="xl">
              <Text>DID: </Text>
              {width <= 700 ? (
                <Code>{truncateHash(user.cdd)}</Code>
              ) : (
                <Code>{user.cdd}</Code>
              )}
              <CopyButton value={user.cdd} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy"}
                    withArrow
                    position="right"
                  >
                    <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                      {copied ? (
                        <IconCheck size="1rem" />
                      ) : (
                        <IconCopy size="1rem" />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </div>
        </SimpleGrid>
      </Card.Section>
    </Card>
  );
}
