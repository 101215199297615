import {
  createAction,
  createSlice,
  createAsyncThunk,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { IOfferingDetails, IToken } from "./types/index";
import _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

export interface IOfferingState {
  response: IOfferingDetails[];
  loading: boolean;
  error: object;
}

const initialState: IOfferingState = {
  response: [],
  loading: true,
  error: {},
};

export const fetchTokenOfferings = createAsyncThunk(
  "tokens/offerings",
  async ({ ticker, service }: { ticker: string; service: any }) => {
    try {
      const { data: response } = await service.request.post(
        `${API_URL}/sto/offeringsbyticker`,
        { ticker: ticker }
      );
      return response;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export const offeringSlice = createSlice({
  name: "token/offerings",
  initialState,
  reducers: {
    // selectToken: (state, action) => {
    //   console.log("===> Action Payload : ", action);
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTokenOfferings.pending, (state) => {
      state.response = [];
      state.loading = true;
    });
    builder.addCase(fetchTokenOfferings.fulfilled, (state, { payload }) => {
      console.log("===> Offerings  Payload : ", payload);
      state.response = payload.data;
      state.loading = false;
    });
    builder.addCase(fetchTokenOfferings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const offeringStateSelector = (state: any) => state.offerings;
export default offeringSlice.reducer;
