import React, { useEffect } from "react";
import { Card } from "@mantine/core";
import Divider from "../Shared/Divider";

import {
  useAppDispatch,
  useAppSelector,
  setActiveTransactionPage,
} from "../../app/index";

const HistoryCardButtons = () => {
  const [selected, setSelected] = React.useState("deposit");
  const dispatch = useAppDispatch();
  const trPages = useAppSelector((state) => state.transactionPage);

  function linkClicked(event: any) {
    console.log("Link Clicked ... ");
    setSelected(selected === "deposit" ? "withdraw" : "deposit");
    dispatch(
      setActiveTransactionPage(selected === "deposit" ? "withdraw" : "deposit")
    );
    event.preventDefault();
  }

  useEffect(() => {
    // console.log("HistoryCardButtons useEffect ... ", trPages.activePage);
  }, [selected]);

  return (
    <Card
      shadow="lg"
      padding="xl"
      radius="lg"
      className="bg-white w-full overflow-hidden h-[150px]"
    >
      <div className="w-full flex flex-col items-start justify-start gap-3 font-manrope">
        <p className="text-black text-base font-bold">Transaction History</p>

        <Divider />

        <p className="text-xs text-brand-secondary cursor-pointer font-normal">
          <a
            href="#"
            className={`${selected == "deposit" ? "font-bold" : "font-normal"}`}
            onClick={linkClicked}
          >
            All Transactions Deposit & Withdrawal History
          </a>
        </p>
        <p className="text-xs text-[#1B1B1B] hover:text-brand-secondary cursor-pointer font-normal">
          <a
            href="#"
            className={`${
              selected == "withdraw" ? "font-bold" : "font-normal"
            }`}
            onClick={linkClicked}
          >
            STO Investment History
          </a>
        </p>
      </div>
    </Card>
  );
};

export default HistoryCardButtons;
