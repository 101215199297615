import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

export interface IExchangeOffer {
  id: number;
  userid: number;
  ticker: string;
  amount: number;
  amount_left: number;
  price: number;
  offer_status: string;
  created_at: string;
  address: string;
  cdd: string;
}

export interface IExchangeOffers {
  response: IExchangeOffer[];
  loading: boolean;
  error: object;
}

const initialState: IExchangeOffers = {
  response: [],
  loading: true,
  error: {},
};

export const fetchExchangeOffers = createAsyncThunk(
  "exchange/offers",
  async (service: AxiosResponse) => {
    try {
      const { data: response } = await service.request.post(
        `${API_URL}/exchange/offers`,
        { limit: 10 } // limit: 10 is a temporary solution we dont control on serverside yet
      );
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExchangeOffers.pending, (state) => {
      state.response = [];
      state.loading = true;
    });
    builder.addCase(fetchExchangeOffers.fulfilled, (state, { payload }) => {
      state.response = payload.data;
      state.loading = false;
    });
    builder.addCase(fetchExchangeOffers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const exchangeStateSelector = (state: any) => state.exchange;
export default exchangeSlice.reducer;
