import React from "react";
import WalletCard from "../Dashboard/WalletCard";
import ExchangeCard from "./ExchangeCard";
// import HistoryCard from "./HistoryCard";

const TransactionsPageNew = () => {
  return (
    <div className="w-full h-full  bg-[url('assets/Dashboard2Bg.png')] bg-no-repeat bg-cover bg-bottom md:px-10 px-4 py-1">
      <div className="w-full grid xl:grid-cols-[1fr,3fr] gap-6 pb-3">
        <div>
          <WalletCard showButtons={false} />
        </div>
        <div>
          {" "}
          <ExchangeCard />{" "}
        </div>
      </div>
    </div>
  );
};

export default TransactionsPageNew;
