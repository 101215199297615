import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { IoIosInformationCircle } from "react-icons/io";
import Hexagon from "../../assets/Hexagon.png";

import {
  Image,
  ActionIcon,
  Button,
  Card,
  createStyles,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  Skeleton,
  Loader,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import Deposit from "../Maei/DepositDrawer";

import {
  useAppSelector,
  useAppDispatch,
  openDepositWindow,
  changeWithdrawWindowState,
  fetchBlackBalance,
} from "../../app/";
import Withdraw from "../Maei/WithdrawDrawer";
import Context from "../../context/Context";
import { formatNumber } from "../../helper";

interface Props {
  showButtons?: boolean;
}

const WalletCard: React.FC<Props> = ({ showButtons = false }) => {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);

  function handleOpenDepositWindow() {
    dispatch(openDepositWindow(1));
  }

  useEffect(() => {
    const { service, user } = context.globalstate;
    dispatch(fetchBlackBalance(service));
  }, []);

  // I deceide to create another return component for no buttons
  // because we dont need additional rendering for drawers

  if (!showButtons) {
    return (
      <Card
        shadow="lg"
        padding="xl"
        radius="lg"
        className="bg-gradient-to-b from-brand-main to-brand-secondary w-full overflow-hidden"
      >
        <LoadingOverlay visible={userState.loading} />
        <div className="w-full flex flex-col items-start justify-start gap-12 relative">
          <div className="absolute h-[190px] w-[170px] -top-20 -left-10">
            <div className="w-full h-full relative">
              <Image
                className="objecct-cover w-full h-full"
                src={Hexagon}
                alt=""
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-4 z-10">
            <div className="w-full flex items-center justify-start gap-2">
              <IoWalletOutline className="text-[24px] text-white" />
              <p className="text-white text-sm font-normal font-manrope">
                Wallet
              </p>
            </div>
            <div className="w-full flex items-end justify-start gap-2">
              {/* <LoadingOverlay visible={userState.loading} overlayBlur={0.01} /> */}

              {userState.loading && (
                <>
                  {/* <p className="text-white font-bold font-manrope">Loading</p> */}
                  <Loader color="gray" variant="dots" />;
                </>
              )}

              {!userState.loading && (
                <>
                  <p className="text-white text-4xl font-bold font-manrope">
                    {formatNumber(userState.blackBalance)}
                  </p>
                  <p className="text-white text-base font-manrope font-bold">
                    Maei
                  </p>
                </>
              )}

              <Tooltip label="1 Maei = 1 Australian Dollar">
                <ActionIcon variant="transparent">
                  <IoIosInformationCircle className="text-[24px] text-gray-400" />
                </ActionIcon>
              </Tooltip>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <>
      <Deposit />
      <Withdraw />

      <Card
        shadow="lg"
        padding="xl"
        radius="lg"
        className="bg-gradient-to-b from-brand-main to-brand-secondary w-full overflow-hidden"
      >
        <LoadingOverlay visible={userState.loading} />
        <div className="w-full flex flex-col items-start justify-start gap-12 relative">
          <div className="absolute h-[190px] w-[170px] -top-20 -left-10">
            <div className="w-full h-full relative">
              <Image
                className="objecct-cover w-full h-full"
                src={Hexagon}
                alt=""
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-4 z-10">
            <div className="w-full flex items-center justify-start gap-2">
              <IoWalletOutline className="text-[24px] text-white" />
              <p className="text-white text-sm font-normal font-manrope">
                Wallet
              </p>
            </div>
            <div className="w-full flex items-end justify-start gap-2">
              <p className="text-white text-4xl font-bold font-manrope">
                {formatNumber(userState.blackBalance)}
              </p>
              <p className="text-white text-base font-manrope font-bold">
                Maei
              </p>

              <Tooltip label="1 Maei = 1 Australian Dollar">
                <ActionIcon variant="transparent">
                  <IoIosInformationCircle className="text-[24px] text-gray-400" />
                </ActionIcon>
              </Tooltip>
            </div>
          </div>

          <div className="w-full flex items-center justify-start gap-6 z-10">
            <Button
              size="md"
              onClick={() => handleOpenDepositWindow()}
              className="wallet-button flex items-center justify-center bg-gradient-to-r from-[#FFC243] via-[#FFD784] to-[#FFC243] rounded-[9px] text-base text-black font-manrope font-bold py-2 px-8"
            >
              Deposit
            </Button>

            <Button
              size="md"
              variant="default"
              onClick={() => dispatch(changeWithdrawWindowState())}
              className="wallet-button flex items-center justify-center bg-white rounded-[9px] text-base text-black font-manrope font-bold py-2 px-8"
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default WalletCard;
