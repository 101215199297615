import {
  Button,
  Drawer,
  Group,
  LoadingOverlay,
  SimpleGrid,
  TextInput,
  Text,
} from "@mantine/core";
import { useState, useEffect, useContext } from "react";
import { showNotification } from "@mantine/notifications";
import {
  useAppSelector,
  useAppDispatch,
  changeInvestWindowState,
  IInvestSTORequest,
  sendInvestSTOTransaction,
} from "../../app/index";
import Context from "../../context/Context";
import { useForm } from "@mantine/form";
import { apolloClient } from "../../app/graphClient";

// passing IofferingDetails as props
export default function InvestSTODrawer() {
  const dispatch = useAppDispatch();
  const context = useContext(Context);
  const { service } = context.globalstate;

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [confirmActive, setConformActive] = useState<boolean>(false);

  const { investWindowOpened, selectedSto, loading, response, error } =
    useAppSelector((state) => state.investsto);

  const form = useForm({
    initialValues: {
      amount: "",
      ticker: "",
    },
  });

  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    console.log("apolloClient Clear The Cache ... ");
    await apolloClient.cache.reset();

    setButtonDisabled(true);
    form.setValues({ ticker: selectedSto?.ticker });
    let _selectedTiker: string | undefined = selectedSto?.ticker;

    const _amount = parseFloat(values.amount);

    // So why we decided to use this method of sending data to the backend
    // Because form components have an bug and sometimes its not setting the values correctly !!!

    // Strong control on the data
    if (!_selectedTiker || !selectedSto) {
      showNotification({
        color: "red",
        id: "notif-error",
        title: "Error",
        message: "Some data is missing !!!",
        autoClose: false,
      });
      return;
    }

    const investStoTransactionRequest: IInvestSTORequest = {
      purchaseAmount: _amount,
      stoid: selectedSto.id,
      ticker: selectedSto.ticker,
    };

    dispatch(
      sendInvestSTOTransaction({
        data: investStoTransactionRequest,
        service: service,
      })
    ).then((unwrapResult) => {
      console.log(
        "InvestSTODrawer() ==> handleSubmit() ==> unwrapResult: ",
        unwrapResult
      );

      if (unwrapResult.payload.success) {
        form.reset();
        setButtonDisabled(false);
        dispatch(changeInvestWindowState(null));
      } else {
        setButtonDisabled(false);
      }
      //TODO: check if the transaction is successfull or not
    });
  };

  function handleClose() {
    console.log("InvestSTODrawer() ==> handleClose()");
    setButtonDisabled(false);
    form.reset();
    dispatch(changeInvestWindowState(null));
  }

  useEffect(() => {}, []);

  return (
    <Drawer
      opened={investWindowOpened}
      onClose={() => handleClose()}
      padding="xl"
      size="md"
      position="right"
      withOverlay={true}
      overlayProps={{ opacity: 0.1, blur: 0.1 }}
    >
      <LoadingOverlay visible={loading} />

      <SimpleGrid cols={1} spacing="md" verticalSpacing="md">
        <div>
          {/* Confim Purchase is depricated */}
          {/* {confirmActive && (
            <Paper withBorder p="lg" radius="md" shadow="md">
              <Group position="apart" mb="xs">
                <Text fz="md" fw={500}>
                  Confirm Transaction
                </Text>
                <CloseButton mr={-9} mt={-9} />
              </Group>
              <Text c="dimmed" fz="md">
                Confirm Purchase: {form.values.amount} {selectedSto?.ticker}{" "}
                {parseFloat(form.values.amount) * selectedSto?.tiers[0].price}{" "}
                Maei &nbsp;{" "}
              </Text>
              <Group position="right" mt="md">
                <Button color="dark" size="md" onClick={confirmPurchase}>
                  Confirm
                </Button>
              </Group>
            </Paper>
          )} */}

          <br />

          {/* {!confirmActive && ( */}
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              type="number"
              placeholder="100"
              label="Enter Token amount you want to buy"
              size="md"
              {...form.getInputProps("amount")}
            />
            <>
              {form.values.amount !== "" && (
                <Text fz="xs" mt={10}>
                  {form.values.amount} {selectedSto?.ticker}. The total price
                  is:{" "}
                  {parseFloat(form.values.amount) * selectedSto?.tiers[0].price}{" "}
                  Maei &nbsp;{" "}
                </Text>
              )}
            </>
            <Group position="right" spacing="md" mt={10}>
              <Button
                type="submit"
                color="dark"
                size="md"
                classNames={{ root: "bg-brand-main" }}
                loading={buttonLoading}
                disabled={buttonDisabled}
              >
                {" "}
                Purchase
              </Button>
            </Group>
          </form>
          {/* )} */}
        </div>
      </SimpleGrid>
    </Drawer>
  );
}
