import {
  createStyles,
  Card,
  Group,
  Text,
  LoadingOverlay,
  Anchor,
  Divider,
} from "@mantine/core";

import {
  useAppSelector,
  selecAssetsWithActiveSto,
  selecAssetsWithActiveSto_func,
} from "../../app/index";
import { useNavigate } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `$1rem solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },
}));

export default function TokensGraphWidget() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const graphData = useAppSelector((state) => state.graph);

  // dispatch(selecAssetsWithActiveSto());

  useEffect(() => {
    console.log("TokensGraphWidget.tsx useEffect graphData : ", graphData);
  }, []);

  if (graphData.loading) {
    return (
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={true} overlayBlur={0.1} />
      </div>
    );
  }

  const items = graphData.response?.assets.nodes.map((item) => (
    <Group
      position="apart"
      className={classes.item}
      noWrap
      spacing="md"
      key={item.ticker}
    >
      <div>
        <Anchor
          color={"dark"}
          component="button"
          type="button"
          onClick={() => navigate(`/token/${item.ticker}`)}
        >
          {item.ticker}
        </Anchor>
        <Text size="xs" color="dimmed">
          {item.name}
        </Text>
      </div>
    </Group>
  ));

  const items2 = selecAssetsWithActiveSto_func(graphData).map((asset) => (
    <Group
      position="apart"
      className={classes.item}
      noWrap
      spacing="md"
      key={asset.ticker}
    >
      <div>
        <Anchor
          color={"dark"}
          component="button"
          type="button"
          onClick={() => navigate(`/token/${asset.ticker}`)}
        >
          {asset.ticker}
        </Anchor>
        <Text size="xs" color="dimmed">
          {asset.name}
        </Text>
      </div>
    </Group>
  ));

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={false} overlayBlur={0.1} />
      <Card withBorder radius="md" p="xl" className={classes.card}>
        <Text fz="lg" className={classes.title} fw={700}>
          {"Security Tokens"}
        </Text>
        <Divider mt="md" mb="md" />
        {items2}
      </Card>
    </div>
  );
}
