import { useContext, useEffect } from "react";
import { LoadingOverlay } from "@mantine/core";
import Context from "../../context/Context";

import {
  useAppDispatch,
  useAppSelector,
  fetchExchangeOffers,
  IExchangeOffer,
} from "../../app/index";
import BuyTokenFromExchangeModal from "./BuyTokenModal";
import AssetComponent from "../Components/AssetComponent";
import { formatNumber } from "src/helper";
import MantineTable from "../Shared/MantineTable";
import Divider from "../Shared/Divider";

export default function ExchangeCard() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const exchangeData = useAppSelector((state) => state.exchange);

  useEffect(() => {
    dispatch(fetchExchangeOffers(context.globalstate.service));
  }, []);

  const rows = exchangeData.response.map(
    (exchangeOffer: IExchangeOffer, index) => (
      <div
        key={index}
        className="w-full flex flex-col items-center justify-start gap-2"
      >
        <tr className="w-full grid sm:grid-cols-[2fr,1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr,1fr] place-items-start">
          <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <AssetComponent ticker={exchangeOffer.ticker} />
          </td>
          <td
            className={`py-[2px] px-[10px] sm:flex hidden rounded text-xs items-center justify-center  font-semibold`}
          >
            {exchangeOffer.amount_left}
          </td>
          <td className="w-full items-center justify-start gap-2 text-black text-xs font-[700]">
            {formatNumber(exchangeOffer.price, 2)}
          </td>
          <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <BuyTokenFromExchangeModal {...exchangeOffer} />
          </td>
        </tr>
        {<Divider />}
      </div>
    )
  );

  return (
    <>
      <LoadingOverlay visible={exchangeData.loading} overlayBlur={0.1} />

      <MantineTable title="Exchange Offer" setting>
        <div className="w-full overflow-auto flex flex-col items-center justify-start gap-4 px-8">
          <thead className="w-full">
            <tr className=" w-full grid sm:grid-cols-[2fr,1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr,1fr] place-items-start">
              <th className="text-xs text-gray-main font-semibold font-manrope">
                Asset
              </th>
              <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
                Amount
              </th>
              <th className="text-xs  text-gray-main font-semibold font-manrope">
                Price
              </th>
              <th className="text-xs text-gray-main font-semibold font-manrope"></th>
            </tr>
          </thead>
          <tbody className="w-full h-full overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar">
            {rows}
          </tbody>
        </div>
      </MantineTable>
    </>
  );
}
