import RoutingComponent from "./pages/RoutingComponent";

export function App() {
  // const matches = useMediaQuery("(min-width: 56.25em)");
  // useEffect(() => {
  //   console.log("APP.tsx useEffect MATCHES : ", matches);
  // }, []);
  return (
    <>
      <RoutingComponent />
    </>
  );
}
