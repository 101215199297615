import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Button,
  Image,
  LoadingOverlay,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import MaeiBigLogo from "../../assets/svg/maei.svg";
import Context from "../../context/Context";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";

const API_URL = process.env.REACT_APP_API_URL;

export default function Register() {
  const context = useContext(Context);
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationFinished, setRegistrationFinished] = useState(false);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
      password1: "",
    },

    validate: {
      username: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid Username Email",
    },
  });

  // Get inferred form values type, will be `{ name: string; age: number }`
  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    const { username, password, password1 } = values;

    console.log("Form values: ", username, password, password1);
    console.log("Compare passwords !!! ", password1 !== password);

    if (password1 !== password) {
      setErrorVisible(true);
      setErrorMessage("Passwords do not match");
      return;
    }

    setProcessing(true);

    const { service } = context.globalstate;

    const { status: httpStatusCode, data } = await service.request.put(
      `${API_URL}/user`,
      values
    );

    console.log("----------------------------------");

    // let httpStatusCode = 408;
    console.log("Register Response: -> httpStatusCode:  ", httpStatusCode);
    console.log("Register Response -> Data :  ", data);

    switch (httpStatusCode) {
      case 200: {
        console.log("Registration Successful");
        setProcessing(false);
        setRegistrationFinished(true);
        navigate("/accountcreated");
        break;
      }
      case 409: {
        console.log("Requested Username Already Exists In Database");
        setErrorMessage("Username Already Exists In Database");
        setErrorVisible(true);
        setProcessing(false);
        break;
      }
      default: {
        console.log(
          "We're sorry, it seems that something went wrong. Please try again later, or contact our administrator for further assistance."
        );
        setErrorMessage(
          "We're sorry, it seems that something went wrong. Please try again later, or contact our administrator for further assistance."
        );
        setErrorVisible(true);
        setProcessing(false);
        break;
      }
    }
  };

  const closeMessage = () => {
    console.log("closeMessage");
    setErrorVisible(false);
    setErrorMessage("");
  };

  return (
    <>
      <div className="w-full min-h-screen flex flex-col items-start justify-start gap-16 bg-[url('./assets/Register.png')] bg-cover bg-no-repeat py-5">
        <div className="w-full flex items-center justify-start">
          <div className="sm:h-[90px] h-[75px] sm:w-[320px] w-[280px] flex items-center justify-center bg-white rounded-r-[100px]">
            <div className="sm:h-[60px] h-[50px] sm:w-[130px] w-[110px] relative">
              <Image
                className="object-cover h-full w-full"
                src={MaeiBigLogo}
                alt="maei.cash logo"
              />
            </div>
          </div>
        </div>
        <div className="w-full grid md:grid-cols-2 md:gap-0 gap-10 place-items-center">
          <aside className="xl:w-[90%] lg:w-[50%] w-full flex flex-col items-center justify-center">
            <h2 className="text-white text-center text-3xl font-medium font-manrope">
              Security Token Offering Platform
            </h2>
            <p className="text-white text-center font-manrope mt-4">
              provided by Marketlend.
            </p>
          </aside>
          <aside className="w-full flex items-center justify-center">
            <form
              onSubmit={form.onSubmit((values) => handleSubmit(values))}
              className="xl:w-[65%] lg:w-[70%] md:w-[85%] w-[95%] flex flex-col items-center justify-start gap-9 bg-white rounded-[35px] py-[33px] sm:px-[48px] px-[30px] shadow-xl"
            >
              <div className="w-full flex flex-col items-center justify-start gap-3">
                <h2 className="font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-brand-main to-brand-secondary">
                  Register
                </h2>
                <p className="text-black text-base text-center font-semibold">
                  Stable Dollar Wallet
                </p>
              </div>
              <div className="w-full flex flex-col items-start justify-start gap-5">
                {/* ...... */}

                {errorVisible && (
                  <div className="w-full flex flex-col bg-red-500 text-white px-4 py-2 rounded-md shadow-md">
                    <div className="flex items-center justify-between">
                      <span className="font-bold">{errorMessage}</span>

                      <span onClick={closeMessage}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6 ml-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                )}

                {/* ...... */}

                <LoadingOverlay visible={processing} overlayBlur={0.2} />

                <div className="w-full flex flex-col items-start justify-start gap-1">
                  <TextInput
                    withAsterisk
                    className="w-full"
                    classNames={{
                      input:
                        "rounded-[5px] border border-[#CED4DA] text-base font-normal !font-manrope",
                    }}
                    label="Email"
                    placeholder="Input your email address"
                    required
                    size="lg"
                    type="email"
                    icon={<HiOutlineMail size={20} className="text-black" />}
                    {...form.getInputProps("username")}
                  />
                </div>

                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <PasswordInput
                    label="Password"
                    placeholder="Your password"
                    required
                    mt="md"
                    className="w-full"
                    classNames={{
                      input:
                        "rounded-[5px] border border-[#CED4DA] text-base font-normal font-manrope placeholder:text-base",
                      innerInput: "text-base",
                      visibilityToggle: "text-black",
                      label: "text-sm font-semibold text-black mb-1",
                    }}
                    icon={<MdOutlineLock size={20} className="text-black" />}
                    size="lg"
                    {...form.getInputProps("password")}
                  />
                </div>

                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <PasswordInput
                    label="Repeate password"
                    placeholder="Repeate password"
                    required
                    mt="md"
                    className="w-full"
                    classNames={{
                      input:
                        "rounded-[5px] border border-[#CED4DA] text-base font-normal font-manrope placeholder:text-base",
                      innerInput: "text-base",
                      visibilityToggle: "text-black",
                      label: "text-sm font-semibold text-black mb-1",
                    }}
                    icon={<MdOutlineLock size={20} className="text-black" />}
                    size="lg"
                    {...form.getInputProps("password1")}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-start gap-5">
                <Button
                  type="submit"
                  color="dark"
                  size="md"
                  className="w-full h-[54px] flex items-center justify-center text-base text-white font-semibold font-sans rounded-[9px] bg-gradient-to-b from-brand-main to-brand-secondary"
                >
                  Create an Account
                </Button>

                <div className="w-full flex items-center justify-center gap-1">
                  <p className="text-sm text-black font-medium">
                    If you have an account go to{" "}
                    <Link to={"/login"}> Login </Link>
                  </p>
                </div>
              </div>
            </form>
          </aside>
        </div>
      </div>
    </>
  );
}
