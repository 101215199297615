import { useEffect, useState } from "react";
import { Card, LoadingOverlay, Table, createStyles, rem } from "@mantine/core";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import { IconCalendarDue } from "@tabler/icons-react";
import InvestSTODrawer from "./InvestSTODrawer";

import {
  useAppSelector,
  useAppDispatch,
  changeInvestWindowState,
  fetchActiveStos,
  filterStosByStatus,
  ITokenMetadata,
} from "../../app/index";
import { formatNumber } from "../../helper";
import { mt, tr } from "date-fns/locale";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[5],
  },
}));

const calcPercent = (totalAmount: number, totalRemaining: number) => {
  if (totalAmount === totalRemaining) return 0.0;
  let _percent = ((totalAmount - totalRemaining) / totalAmount) * 100;
  return parseFloat(_percent.toFixed(2));
};

export default function ActiveStosWidget() {
  const dispatch = useAppDispatch();
  const { classes, theme } = useStyles();
  const [daysLeft, setDaysLeft] = useState("");
  const [percent, serPercent] = useState(0);

  const activeStos = useAppSelector((state) => state.activeStos);
  const assetsWithMetadata = useAppSelector((state) => state.tokensmetadata);

  useEffect(() => {
    // First, dispatch the fetch action
    dispatch(fetchActiveStos())
      .then(() => {
        // After the fetch is done, filter the STOs by status
        dispatch(filterStosByStatus("Live"));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);

  useEffect(() => {}, []);

  if (activeStos.loading) return <div>Loading...</div>;

  function getTokenMetadata(ticker: string): {
    desc: string;
    term_month: string;
    average_rate: string;
    logo: string;
  } {
    // const asset = assetsWithMetadata.assets.find(
    //   (asset) => asset.ticker === ticker
    // );

    const asset = assetsWithMetadata.assets[ticker];

    // Default values
    const defaultValues = {
      desc: "No description available",
      term_month: "N/A",
      average_rate: "0.0%",
      logo: "default_logo_path.png",
    };

    if (!asset) {
      return defaultValues;
    }
    const { desc, term_month, average_rate, logo } = asset;

    return { desc, term_month, average_rate, logo };
  }

  const rows = activeStos.response?.stos.nodes.map((sto, id) => {
    return (
      <tr key={id}>
        <td>{sto.offeringAsset.ticker}</td>
        <td>{getTokenMetadata(sto.offeringAsset.ticker).desc}</td>
        <td>{formatNumber(sto.tiers[0].total / 1000000)}</td>
        <td>{getTokenMetadata(sto.offeringAsset.ticker).term_month}</td>
        <td>{getTokenMetadata(sto.offeringAsset.ticker).average_rate}</td>
        {/* <td></td> */}
        {/* <td></td> */}
      </tr>
    );
  });

  return (
    <div>
      <InvestSTODrawer />
      <div style={{ position: "relative", marginTop: "10px" }}>
        <LoadingOverlay visible={false} overlayBlur={0.1} />
        <Card withBorder radius="md" p="xl" className={classes.card}>
          <Card.Section withBorder inheritPadding py="xs">
            <h4>Marketplace</h4>
          </Card.Section>
          <Table verticalSpacing="md" fontSize="sm" highlightOnHover>
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Title</th>
                <th>Amount</th>
                <th>Term(month)</th>
                <th>Avg. Rate</th>
                {/* <th>% Funded</th> */}
                {/* <th>Time Left</th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Card>
      </div>
    </div>
  );
}
