import React from "react";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";

import {
  useAppDispatch,
  useAppSelector,
  fetchExchangeOffers,
  IExchangeOffer,
} from "../../app/index";
import { useNavigate } from "react-router-dom";
import BuyTokenFromExchangeModal from "../Exchange/BuyTokenModal";
import MantineTable from "../Shared/MantineTable";
import Divider from "../Shared/Divider";
import AssetComponent from "../Components/AssetComponent";
import { formatNumber } from "src/helper";

const TokensExchangeTable = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const exchangeData = useAppSelector((state) => state.exchange);

  useEffect(() => {
    dispatch(fetchExchangeOffers(context.globalstate.service));
  }, []);

  const rows = exchangeData.response.map(
    (exchangeOffer: IExchangeOffer, index) => (
      <div
        key={index}
        className="w-full flex flex-col items-center justify-start gap-2"
      >
        <tr className="w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr,1fr] place-items-start">
          <td className="text-black text-xs font-semibold sm:inline-block hidden">
            {index + 1}
          </td>
          <td className="w-full flex items-center justify-start gap-2 text-black text-xs font-[700]">
            <AssetComponent ticker={exchangeOffer.ticker} />
          </td>
          <td className="text-sm sm:inline-block hidden text-black font-semibold">
            {exchangeOffer.amount_left}
          </td>
          <td className="text-sm text-black  font-semibold">
            {formatNumber(exchangeOffer.price)}
          </td>
          <td className="w-full flex items-center justify-end">
            <BuyTokenFromExchangeModal {...exchangeOffer} />
          </td>
        </tr>
        {<Divider />}
      </div>
    )
  );

  return (
    <MantineTable title="Peer To Peer Exchange">
      <div className="w-[99%] overflow-auto flex flex-col items-center justify-start gap-4">
        <thead className="w-full px-8">
          <tr className=" w-full grid sm:grid-cols-[.5fr,1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr,1fr] place-items-start">
            <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
              No
            </th>
            <th className="text-xs text-gray-main font-semibold font-manrope">
              Asset
            </th>
            <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
              Amount
            </th>
            <th className="text-xs  text-gray-main font-semibold font-manrope">
              Price
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody className="w-full max-h-[200px] overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar px-8">
          {rows}
        </tbody>
      </div>
    </MantineTable>
  );
};

export default TokensExchangeTable;
