import { AppShell, Center, Grid, Header, Navbar } from "@mantine/core";
import { AppHeader, AppNavbar } from "./Components/Index";
import { WalletCard } from "./Maei";
import { TokensGraphWidget } from "./Tokens";
import MyAssets from "./Tokens/MyAssetsWidget";
import AsidePanel from "./Components/AsidePanel";
import ExchangeCard from "./Exchange/ExchangeCard";
import ActiveStosWidget from "./Sto/ActiveStosWidget";

export default function Home() {
  return (
    <AppShell
      navbar={<AppNavbar />}
      header={<AppHeader />}
      aside={<AsidePanel />}
      padding={30}
    >
      <Grid grow gutter="xl">
        <Grid.Col span={8}>
          <WalletCard />
          <MyAssets />
          <ExchangeCard />
          <ActiveStosWidget />
        </Grid.Col>
        <Grid.Col span={4}>
          <TokensGraphWidget />
        </Grid.Col>
      </Grid>
    </AppShell>
  );
}
