import {
  Burger,
  Group,
  Header,
  MediaQuery,
  useMantineTheme,
} from "@mantine/core";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { LogoMaeyBoxLagre } from "../../assets";
import Context from "../../context/Context";
import UserMenu from "./UserMenu";
import {
  useAppDispatch,
  useAppSelector,
  openNavbar,
  closeNavbar,
} from "../../app/index";

export default function AppHeader() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const context = useContext(Context);

  const dispatch = useAppDispatch();
  const appSettings = useAppSelector((state) => state.appsettings);

  const handleSetOpened = () => {
    console.log("AppHeader: ==> HandleSetOpened");
    dispatch(openNavbar(!appSettings.navigationOpened));
  };

  // return (
  //   <Header height={{ base: 90, md: 90 }} p="md">
  //     <div style={{ display: "flex", alignItems: "apart", height: "100%" }}>
  //       <MediaQuery largerThan="sm" styles={{ display: "none" }}>
  //         <Burger
  //           opened={opened}
  //           onClick={() => setOpened((o) => !o)}
  //           size="sm"
  //           color={theme.colors.gray[6]}
  //           mr="xl"
  //         />
  //       </MediaQuery>

  //       <Group position="apart" spacing="lg">
  //         <Link to="/">
  //           <img src={LogoMaeyBoxLagre} alt="Maei Logo" height="60" />
  //         </Link>
  //         <Code sx={{ fontWeight: 700 }}>v3.5.0</Code>

  //         <Avatar color="blue" radius="sm">
  //           <IconStar size="1.5rem" />
  //         </Avatar>
  //       </Group>
  //     </div>
  //   </Header>
  // );

  return (
    <Header height={{ base: 90, md: 90 }} p="md">
      <Group position="apart" spacing="md">
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          > */}
          <Burger
            opened={appSettings.navigationOpened}
            onClick={() => handleSetOpened()}
            size="lg"
            color={theme.colors.gray[6]}
            mr="lg"
            // transitionDuration={0.7}
          />
          {/* </div> */}
        </MediaQuery>
        <Link to="/">
          <img src={LogoMaeyBoxLagre} alt="Maei Logo" height="60" />
        </Link>
        {/* <UserMenu /> */}
      </Group>
    </Header>

    /*
     */
  );
}
