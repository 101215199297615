import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IState {
  depositWindowOpened: boolean;
  withdrawWindowOpened: boolean;
  response: any;
  loading: boolean;
  error: any;
}

export interface ITransactionRequest {
  amount: number
  transactionType: number;
  currency: string;
}

const initialState: IState = {
  depositWindowOpened: false,
  withdrawWindowOpened: false,
  response: {},
  loading: true,
  error: {},
};

const API_URL = process.env.REACT_APP_API_URL;

export const transactionRequest = createAsyncThunk<any, any>(
  "user/transactions",
  async (data: ITransactionRequest, service: any) => {
    try {
      const { data: response } = await service.request.post(
        `${API_URL}/user/funds`,
        data
      );

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const maeiSlice = createSlice({
  name: "maei",
  initialState,
  reducers: {
    openDepositWindow: (state, action) => {
      console.log("==> OpenDepositWindow Action Payload: ", action);
      state.depositWindowOpened = true;
    },
    closeDepositWindow: (state, action) => {
      console.log("==> OpenDepositWindow Action Payload: ", action);
      state.depositWindowOpened = false;
    },
    changeWithdrawWindowState: (state) => {
      console.log("==> OpenWithdrawWindow Action Payload: ", state);
      state.withdrawWindowOpened = !state.withdrawWindowOpened;
    },
  },
});

export const maeiStateSelector = (state: any) => state.maei;

export const {
  openDepositWindow,
  closeDepositWindow,
  changeWithdrawWindowState,
} = maeiSlice.actions;
export default maeiSlice.reducer;
