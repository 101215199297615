export interface PageObject {
  title: string;
  content?: string;
}

// Create a map with default data
export const pagesMetadata: Map<string, PageObject> = new Map([
  ["", { title: "Maei Dashboard" }],
  ["tokens", { title: "Security Tokens" }],
  ["token", { title: "Security Token" }],
  ["exchange", { title: "Peer to Peer Exchange" }],
  ["transactions", { title: "Transactions History" }],
  ["settings", { title: "Settings" }],
]);

// Retrieve a page from the map
// const activePageData: PageObject | undefined = pages.get("/");
// if (activePageData) {
//   console.log(`Title: ${dashboardPage.title}`);
// }
