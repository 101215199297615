import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { assets_metadata } from "./storage/asstes_metadata";
import { IAssetsMetadata, ITokenMetadata } from "./types/tokensMetadata";

export interface IMetaAssets {
  assets: IAssetsMetadata;
  loading: boolean;
  error: object;
}

const initialState: IMetaAssets = {
  assets: assets_metadata,
  loading: false, // Offline storage sync loading
  error: {},
};

export const tokensMetadataSlice = createSlice({
  name: "tokens/metadata",
  initialState,
  reducers: {
    selectTokenMetadata: (state, action) => {
      // console.log("===> Get Token Metadata ...  : ", action);
    },
  },
});

export const tokensMetadataStateSelector = (state: any) => state.metadata;
export default tokensMetadataSlice.reducer;
