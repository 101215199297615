import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PasswordInput,
  Image,
  Input,
  Button,
  Container,
  LoadingOverlay,
  Box,
  Alert,
  TextInput,
} from "@mantine/core";

import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";

import { useForm } from "@mantine/form";
import MaeiBigLogo from "../../assets/svg/maei.svg";
import Context from "../../context/Context";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";

const REACT_APP_STAGE = process.env.REACT_APP_STAGE;
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// Fil inputs for quick login
const REACT_APP_USER = process.env.REACT_APP_USER || "";
const REACT_APP_PASSWORD = process.env.REACT_APP_PASSWORD || "";

export default function Login() {
  const context = useContext(Context);
  let navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const [inputs, setInputs] = useState({
    email: REACT_APP_USER,
    password: REACT_APP_PASSWORD,
  });

  // For quick Login: TODO: Remove in production
  let [username, setUsername] = useState(REACT_APP_USER);
  let [password, setPassword] = useState(REACT_APP_PASSWORD);

  const form = useForm({
    initialValues: {
      username: username,
      password: password,
    },

    validate: {
      username: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid Username Email",
    },
  });

  const handleInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  // Get inferred form values type, will be `{ name: string; age: number }`
  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    const { username, password } = values;
    setProcessing(true);
    const { login } = context.handlers;
    const { status: httpStatusCode, data } = await login(username, password);

    switch (httpStatusCode) {
      case 200: {
        console.log("Login Success !!! Redirect To Home");
        navigate("/");
        break;
      }
      case 404: {
        console.log("User Not Found");

        // showNotification({
        //   color: "red",
        //   id: "error-login",
        //   title: "Login Error",
        //   message: "The username or password is incorrect.",
        //   autoClose: false,
        // });

        setLoginErrorMessage("Wrong username or password");
        setLoginError(true);
        setProcessing(false);
        break;
      }
      default: {
        console.log("Something Wrong Happned , Please ask Administrator");
        // showNotification({
        //   color: "red",
        //   id: "error-login",
        //   title: "Login Error",
        //   message:
        //     "Oops! Something went wrong. Please try again later or contact our support team.",
        //   autoClose: false,
        // });

        setLoginErrorMessage(
          "Something Wrong Happned , Please ask Administrator"
        );
        setLoginError(true);
        setProcessing(false);
        break;
      }
    }
  };

  return (
    <>
      <div className="w-full min-h-screen flex flex-col items-start justify-start gap-16 bg-[url('./assets/Register.png')] bg-cover bg-no-repeat py-5">
        <div className="w-full flex items-center justify-start">
          <div className="sm:h-[90px] h-[75px] sm:w-[320px] w-[280px] flex items-center justify-center bg-white rounded-r-[100px]">
            <div className="sm:h-[60px] h-[50px] sm:w-[130px] w-[110px] relative">
              <Image
                className="object-cover h-full w-full"
                src={MaeiBigLogo}
                alt="maei.cash logo"
              />
            </div>
          </div>
        </div>
        <div className="w-full grid md:grid-cols-2 md:gap-0 gap-10 place-items-center">
          <aside className="xl:w-[90%] lg:w-[50%] w-full flex flex-col items-center justify-center">
            <h2 className="text-white text-center text-3xl font-medium font-manrope">
              Security Token Offering Platform
            </h2>
            <p className="text-white text-center font-manrope mt-4">
              provided by Marketlend.
            </p>
          </aside>
          <aside className="w-full flex items-center justify-center">
            <form
              onSubmit={form.onSubmit((values) => handleSubmit(values))}
              className="xl:w-[65%] lg:w-[70%] md:w-[85%] w-[95%] flex flex-col items-center justify-start gap-9 bg-white rounded-[35px] py-[33px] sm:px-[48px] px-[30px] shadow-xl"
            >
              <div className="w-full flex flex-col items-center justify-start gap-3">
                <h2 className="font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-brand-main to-brand-secondary">
                  Login
                </h2>
                <p className="text-black text-base text-center font-semibold">
                  Stable Dollar Wallet
                </p>
              </div>
              <div className="w-full flex flex-col items-start justify-start gap-5">
                {loginError && (
                  <>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Login Error"
                      color="red"
                      withCloseButton
                      onClose={() => setLoginError(false)}
                    >
                      {loginErrorMessage}
                    </Alert>
                    <br />
                  </>
                )}
                <LoadingOverlay visible={processing} overlayBlur={0.2} />
                <div className="w-full flex flex-col items-start justify-start gap-1">
                  <TextInput
                    withAsterisk
                    className="w-full"
                    classNames={{
                      input:
                        "rounded-[5px] border border-[#CED4DA] text-base font-normal !font-manrope",
                    }}
                    label="Email"
                    placeholder="name@company.com"
                    required
                    size="lg"
                    type="email"
                    icon={<HiOutlineMail size={20} className="text-black" />}
                    {...form.getInputProps("username")}
                  />
                </div>
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <PasswordInput
                    label="Password"
                    placeholder="Your password"
                    required
                    mt="md"
                    className="w-full"
                    classNames={{
                      input:
                        "rounded-[5px] border border-[#CED4DA] text-base font-normal font-manrope placeholder:text-base",
                      innerInput: "text-base",
                      visibilityToggle: "text-black",
                      label: "text-sm font-semibold text-black mb-1",
                    }}
                    icon={<MdOutlineLock size={20} className="text-black" />}
                    size="lg"
                    {...form.getInputProps("password")}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-start gap-5">
                <Button
                  type="submit"
                  color="dark"
                  size="md"
                  className="w-full h-[54px] flex items-center justify-center text-base text-white font-semibold font-sans rounded-[9px] bg-gradient-to-b from-brand-main to-brand-secondary"
                >
                  Login
                </Button>

                <div className="w-full flex items-center justify-center gap-1">
                  <p className="text-sm text-black font-medium">
                    Don't have an account yet?
                  </p>
                  <p className="text-sm text-black font-bold cursor-pointer">
                    <Link to={"/register"}> Register</Link>
                  </p>
                </div>
              </div>
            </form>
          </aside>
        </div>
      </div>
    </>
  );
}
