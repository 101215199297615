import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import Context from "../context/Context";
import { Login, Register } from "./Auth";
import { AllTokens } from "./Tokens/index";
import Home from "./Home";
import StoPage from "./Sto/StoPage";
import Settings from "./settings";
import MainPage from "./Dashboard/Index";
import Layout from "./Layout";
import TransactionsPageNew from "./Transactions/TransactionsPageNew";
import TokenDetailsPageNew from "./Tokens/TokensDetailsPageNew";
import ExchangePageNew from "./Exchange/ExchangePageNew";
import AccountCreated from "./Auth/AccountCreated";

const useAuth = () => {
  const context = useContext(Context);
  const auth = context.globalstate.isLoggedIn;
  return auth;
};

const ProtectedRoute = ({ children }: { children: any }) => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

function RoutingComponent() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/accountcreated" element={<AccountCreated />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout>
                <MainPage />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/ltest"
          element={
            <Layout>
              <h1>Layout Test... Body components !!! </h1>
            </Layout>
          }
        />

        <Route
          path="/oldhome"
          element={
            <ProtectedRoute>
              {" "}
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path="/token/:ticker"
          element={
            <ProtectedRoute>
              <Layout>
                <TokenDetailsPageNew />{" "}
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/tokens"
          element={
            <ProtectedRoute>
              <Layout>
                <AllTokens />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/sto"
          element={
            <ProtectedRoute>
              {" "}
              <StoPage />{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/exchange"
          element={
            <ProtectedRoute>
              <Layout>
                <ExchangePageNew />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/transactions"
          element={
            <ProtectedRoute>
              <Layout>
                <TransactionsPageNew />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Layout>
                <Settings />
              </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default RoutingComponent;
