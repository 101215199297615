import { LoadingOverlay } from "@mantine/core";
import { Asset, useAppSelector } from "../../app/index";
import AssetComponent from "../Components/AssetComponent";
import MantineTable from "../Shared/MantineTable";
import Divider from "../Shared/Divider";
import _ from "lodash";

function splitCamelCase(str: string) {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

function checkIfAssetHaveActiveSto(asset: Asset) {
  const isDateAfterNow = (dateStr: string) => new Date(dateStr) > new Date();

  const activeBadge = (
    <span className="inline-block bg-green-500 text-white text-xs px-2 py-1 rounded-full">
      Active
    </span>
  );

  const completedBadge = (
    <span className="inline-block bg-yellow-500 text-white text-xs px-2 py-1 rounded-full">
      Completed
    </span>
  );

  const blank = <span></span>;

  const stoNodes = asset.stosByOfferingAssetId.nodes;

  // Check if any of the nodes within stosNodes have nodes > 0
  const hasNodesGreaterThanZero = _.some(
    stoNodes,
    (node) => node.tiers.length > 0
  );

  if (!hasNodesGreaterThanZero) {
    return blank;
  }

  // Check if the status is "Live" and the end date is after the current date
  const isLiveAndEndDateAfterNow = _.some(
    stoNodes,
    (node) => node.status === "Live" && isDateAfterNow(node.end)
  );

  if (isLiveAndEndDateAfterNow) {
    return activeBadge;
  }

  return completedBadge;
}

export default function AllTokensCard() {
  const graphData = useAppSelector((state) => state.graph);

  if (graphData.loading) {
    return (
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={true} overlayBlur={0.1} />
      </div>
    );
  }

  const rows2 = graphData.response?.assets.nodes.map((asset, index) => (
    // <div
    //   key={index}
    //   className="w-full flex flex-col items-center justify-start gap-2"
    // >
    <div className="w-full overflow-auto flex flex-col items-center justify-start gap-4">
      <tr className="w-full grid sm:grid-cols-[1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr] place-items-start justify-start">
        <td className="text-black text-xs font-semibold">
          <AssetComponent ticker={asset.ticker} />
        </td>
        <td className="w-full sm:flex hidden items-center justify-start gap-2 text-black text-xs font-[700]">
          {asset.name}
        </td>
        <td
          className={`py-[2px] px-[10px] rounded text-xs sm:flex hidden items-center justify-center  font-semibold`}
        >
          {splitCamelCase(asset.type)}
        </td>
        <td className="items-center justify-center">
          {" "}
          {checkIfAssetHaveActiveSto(asset)}{" "}
        </td>
      </tr>
      {<Divider />}
    </div>
  ));

  return (
    <MantineTable title="Security tokens" setting>
      <div className="w-full overflow-auto flex flex-col items-center justify-start gap-4 px-8">
        <thead className="w-full">
          <tr className="w-full grid sm:grid-cols-[1fr,1fr,1fr,1fr] grid-cols-[2fr,1fr] place-items-start">
            <th className="text-xs text-gray-main font-semibold font-manrope">
              Offeror
            </th>
            {/* <th className="text-xs text-gray-main font-semibold font-manrope"> */}
            <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
              Name
            </th>
            <th className="text-xs sm:inline-block hidden text-gray-main font-semibold font-manrope">
              Type
            </th>
            <th className="text-xs  text-gray-main font-semibold font-manrope">
              STO Status
            </th>
          </tr>
        </thead>
        <tbody className="w-full h-full overflow-auto flex flex-col items-center justify-start gap-3 font-manrope ThinScrollbar">
          {rows2}
        </tbody>
      </div>
    </MantineTable>
  );
}
