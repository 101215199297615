import React, { useEffect } from "react";
import HistoryCard from "./HistoryCard";
import DepositHistory from "./DepositHistory";

import {
  useAppDispatch,
  useAppSelector,
  setActiveTransactionPage,
} from "../../app/index";
import { tr } from "date-fns/locale";
import StoTransactionsNew from "../Sto/StoTransactionsNew";
import WalletCard from "../Dashboard/WalletCard";

const TransactionsPageNew = () => {
  const trPages = useAppSelector((state) => state.transactionPage);
  useEffect(() => {
    console.log("TransactionsPageNew useEffect ... ", trPages.activePage);
  }, []);

  return (
    <div className="w-full h-full  bg-[url('assets/Dashboard2Bg.png')] bg-no-repeat bg-cover bg-bottom md:px-10 px-4 py-1 overflow-auto hideScrollBar">
      <div className="w-full h-full grid xl:grid-cols-[1fr,3fr] gap-6 pb-3">
        <div>
          <WalletCard showButtons={false} />
          <br />
          <HistoryCard />
        </div>
        {trPages.activePage === "deposit" ? (
          <DepositHistory />
        ) : (
          <StoTransactionsNew />
        )}
      </div>
    </div>
  );
};

export default TransactionsPageNew;
