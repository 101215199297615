import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCheck, IconError404 } from "@tabler/icons-react";
import {
  useAppDispatch,
  fetchExchangeOffers,
  IExchangeOffer,
  fetchBlackBalance,
  fetchSubgraphData,
} from "../../app/index";

import Context from "../../context/Context";
import { IUser } from "src/types";
import { apolloClient } from "../../app/graphClient";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";
const API_URL = process.env.REACT_APP_API_URL;

interface IresponseMessages {
  [key: string]: string;
}

const responseMessages: IresponseMessages = {
  OK: "Operation successful.",
  INVALID_REQUEST: "The request is invalid.",
  ORDER_NOT_FOUND: "Order not found.",
  ORDER_ALREADY_EXECUTED: "Order has already been executed.",
  NOT_ENOUGH_TOKENS_FOR_SALE: "Not enough tokens available for sale.",
  NOT_ENOUGH_MAEI_BALANCE: "Insufficient MAEI balance.",
  ORDER_ALREADY_CANCELLED: "The order has already been cancelled.",
  TOKEN_TRANSFER_FAILED: "Token transfer failed.",
  MAEI_TRANSFER_FAILED: "MAEI transfer failed.",
  CANNOT_BUY_OWN_OFFER: "You cannot buy your own offer.",
};

export default function BuyTokenFromExchangeModal(
  exchangeOffer: IExchangeOffer
) {
  const [opened, { open, close }] = useDisclosure(false);
  const [processing, setProcessing] = useState(false);

  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const { service } = context.globalstate;

  const form = useForm({
    initialValues: {
      ticker: exchangeOffer.ticker,
      amount: "",
      price: "",
      type: "buy",
    },
  });

  type FormValues = typeof form.values;

  //
  const handleSubmit = async (values: FormValues) => {
    console.log("handle Submit !!! ", values);

    console.log("apolloClient Clear The Cache ... ");
    await apolloClient.cache.reset();

    setProcessing(true);

    showNotification({
      color: "teal",
      id: "load-data",
      loading: true,
      title: "Processing Transaction",
      message: "Please wait while we are processing your transaction",
      autoClose: false,
      // disallowClose: true,
    });

    close();

    console.log("Form Data : ", values);

    const { id: orderid, amount: tokensForSale, price, ticker } = exchangeOffer;

    const orderData = {
      orderid: orderid,
      buyamount: values.amount,
    };

    console.log("Order Data : ", orderData);

    const { data: response, status: httpStatusCode } =
      await service.request.post(`${API_URL}/exchange/buy`, orderData);

    console.log(
      "Exchange Transaction Response ==> ",
      response,
      "Status Code: ",
      httpStatusCode
    );

    const notificationStatuseColor = httpStatusCode === 200 ? "teal" : "red";
    const notificationTitle = httpStatusCode === 200 ? "Success" : "Error";
    const notficationIcon =
      httpStatusCode === 200 ? (
        <IconCheck size={16} />
      ) : (
        <IconError404 size={16} />
      );

    const { msg } = response;

    updateNotification({
      id: "load-data",
      color: notificationStatuseColor,
      title: notificationTitle,
      message: responseMessages[msg], // read from response messages
      icon: notficationIcon,
      onClose: () => {
        form.reset();
      },
    });

    // @TODO rewite this fuking agly code !!!
    const { user } = context.globalstate;
    const userAsIUser: IUser = user as IUser;

    if (httpStatusCode === 200) {
      await dispatch(fetchExchangeOffers(service));
      await dispatch(fetchBlackBalance(service));
      // await dispatch(fetchSubgraphData(service));

      dispatch(
        fetchSubgraphData({
          userDid: userAsIUser.cdd,
          adminDid: TOKEN_ISSUER_DID,
        })
      );
    }
  };

  return (
    <>
      <Group position="center">
        <Button
          className="bg-black bg-gradient-to-b from-brand-main to-brand-secondary"
          size="xs"
          onClick={open}
        >
          Buy
        </Button>
      </Group>

      <Modal
        centered={true}
        opened={opened}
        onClose={close}
        size="xs"
        title="Buy Tokens From The Exchange"
      >
        <LoadingOverlay visible={processing} overlayBlur={0.5} />

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            type="number"
            mt={"md"}
            // withAsterisk
            // label="Amount"
            placeholder="100"
            required
            {...form.getInputProps("amount")}
          />

          <Group position="right" mt="sx">
            <Button
              classNames={{ root: "bg-brand-main" }}
              type="submit"
              mt="xl"
            >
              Buy
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
}
