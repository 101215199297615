import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
import NavbarExpanded from "./NavbarExpanded";
import NavbarWithIcons from "./NavbarWithIcons";

export default function AppNavbar() {
  const matches = useMediaQuery("(min-width: 56.25em)");
  useEffect(() => {
    console.log("AppNavbar.tsx useEffect MATCHES : ", matches);
  }, []);

  return (
    <>
      {/* <NavbarExpanded /> */}
      {/* {matches ? <NavbarExpanded /> : null} */}

      <NavbarExpanded />
    </>
  );
}
