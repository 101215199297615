import {
  ActionIcon,
  Button,
  Card,
  createStyles,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconArrowDownCircle,
  IconArrowUpCircle,
  IconInfoCircle,
} from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import Deposit from "./DepositDrawer";

import {
  useAppSelector,
  useAppDispatch,
  openDepositWindow,
  changeWithdrawWindowState,
  fetchBlackBalance,
} from "../../app/";
import Withdraw from "./WithdrawDrawer";
import Context from "../../context/Context";
import { formatNumber } from "../../helper";

const useStyles = createStyles((theme) => ({
  // root: {
  //   padding: theme.spacing.xl * 1.5,
  // },

  value: {
    fontSize: 38,
    fontWeight: 800,
    lineHeight: 1,
    color: "#1CC8B3",
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

const icons = {
  user: IconUserPlus,
  discount: IconDiscount2,
  receipt: IconReceipt2,
  coin: IconCoin,
};

interface StatsGridProps {
  data: {
    title: string;
    icon: keyof typeof icons;
    value: string;
    diff: number;
  }[];
}

export default function WalletCard() {
  const { classes } = useStyles();
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);

  function handleOpenDepositWindow() {
    dispatch(openDepositWindow(1));
  }

  useEffect(() => {
    const { service, user } = context.globalstate;
    dispatch(fetchBlackBalance(service));
  }, []);

  return (
    <>
      <Deposit />
      <Withdraw />

      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={userState.loading} overlayBlur={0.1} />

        {/* <Paper withBorder p="md" radius="md" key={"wallet"}> */}
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <SimpleGrid cols={2}>
            <div>
              <Group position="apart">
                <Text size="xs" color="dimmed" className={classes.title}>
                  {"Wallet"}
                </Text>
              </Group>

              <Group align="flex-end" spacing="xs" mt={8}>
                <Text className={classes.value}>
                  {/* {Intl.NumberFormat().format(userState.blackBalance)} */}
                  {formatNumber(userState.blackBalance)}
                </Text>
                <Text size="sm" weight={400} className={classes.diff}>
                  <span>{"Maei"}</span>
                  <Tooltip label="1 Maei = 1 Australian Dollar">
                    <ActionIcon variant="transparent">
                      <IconInfoCircle size="1.1rem" />
                    </ActionIcon>
                  </Tooltip>
                </Text>
              </Group>

              {/* <Text size="xs" color="dimmed" mt={7}>
                {"* 1 Maei = 1 Australian Dollar"}
              </Text> */}
            </div>
            <div>
              <Group align={"center"} position={"right"}>
                <SimpleGrid cols={1}>
                  <Button
                    leftIcon=<IconArrowDownCircle />
                    onClick={() => handleOpenDepositWindow()}
                    color="dark"
                    size="md"
                  >
                    Deposit
                  </Button>
                  <Button
                    leftIcon=<IconArrowUpCircle />
                    onClick={() => dispatch(changeWithdrawWindowState())}
                    variant="outline"
                    color="dark"
                    size="md"
                  >
                    Withdraw
                  </Button>
                </SimpleGrid>
              </Group>
            </div>
          </SimpleGrid>
        </Card>
      </div>
    </>
  );
}
